import { stringifyUrl } from 'query-string';
import { API_ENDPOINT } from '../../constants/api-endpoint';
import { Account } from '../../constants/model/account';
import { SearchResponse } from '../../constants/search-response';
import { Pageable } from '../../ms-core/models/pageable';
import { MS_API } from '../../ms-core/utils/api';
import { CheckStatusType } from '../../constants/types/check-status-type';

type Params = {
  level?: number;
  email?: string;
  parentId?: string;
  username?: string;
  id?: string;
} & Pageable;

export const getAccounts = (params?: Record<string, any>) => {
  return new Promise<Account[]>((resolve, reject) => {
    MS_API.get<Account[]>(
      stringifyUrl({ url: API_ENDPOINT.ACCOUNTS, query: params }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const getCheckAccountsHistory = (params?: Record<string, any>) => {
  return new Promise<Record<string, any>>((resolve, reject) => {
    MS_API.get<Record<string, any>>(
      stringifyUrl({ url: '/reports/requests', query: params }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const getCheckChart = (params?: Record<string, any>) => {
  return new Promise<Record<string, any>>((resolve, reject) => {
    MS_API.get<Record<string, any>>(
      stringifyUrl({ url: '/reports/requests/chart', query: params }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const getSummaryReport = (params?: Record<string, any>) => {
  return new Promise<Record<string, any>>((resolve, reject) => {
    MS_API.get<Record<string, any>>(
      stringifyUrl({ url: '/reports/requests/statistic', query: params }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export interface ITotalCheck {
  failed: number;
  passed: number;
}
export interface ITotalCheckParams {
  checkedTimeFrom?: string;
  checkedTimeTo?: string;
}

export const getTotalCheck = (params: ITotalCheckParams) => {
  return new Promise<ITotalCheck>((resolve, reject) => {
    MS_API.get<ITotalCheck>(
      stringifyUrl({ url: '/account/statistic', query: { ...params } }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export interface ICheckAccountApiResponse {
  email?: string;
  checked_time?: string;
  check_status?: CheckStatusType;
  extra_data_username?: string;
  extra_data_email?: string;
}

export const checkAccountApi = (email?: string, username?: string) => {
  return new Promise<ICheckAccountApiResponse>((resolve, reject) => {
    MS_API.post<ICheckAccountApiResponse>(stringifyUrl({ url: '/account/check', query: { email, username } }))
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const getAccountsTelesales = (params?: Record<string, any>) => {
  return new Promise<SearchResponse<{ accounts: Account[] }>>((resolve, reject) => {
    MS_API.get<SearchResponse<{ accounts: Account[] }>>(
      stringifyUrl({ url: '/report/telesales/accounts', query: params }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const getAccountId = (username?: string) => {
  return new Promise<string>((resolve, reject) => {
    MS_API.get<string>(
      stringifyUrl({ url: API_ENDPOINT.ACCOUNT_ID, query: { username } }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const getAccountsDetail = (id: string, isTelesales?: boolean) => {
  return new Promise<Account>((resolve, reject) => {
    MS_API.get<Account>(
      stringifyUrl(
        { url: isTelesales ? `/report/telesales/accounts/${id}` : `${API_ENDPOINT.ACCOUNTS}/${id}` },
        { skipEmptyString: true, skipNull: true },
      ),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const getAgents = (params?: Params) => {
  return new Promise<{ accounts: Account[] }>((resolve, reject) => {
    MS_API.get<{ accounts: Account[] }>(
      stringifyUrl(
        { url: '/account/accounts', query: { ...params, showRates: true } },
        { skipEmptyString: true, skipNull: true },
      ),
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};

export const countAgents = () => {
  return new Promise<number>((resolve, reject) => {
    MS_API.get<number>(
      stringifyUrl({ url: '/account/accounts/search/count-agent', query: { totalSubUnitGreaterThan: 0 } }),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const banAccount = (userId: string, reason: string, unblock?: boolean) => {
  return new Promise<boolean>((resolve, reject) => {
    MS_API.post(`/account/accounts/${userId}/${unblock ? 'unban' : 'ban'}`, { remark: reason })
      .then(() => resolve(true))
      .catch(() => reject());
  });
};

export const resetPhoneNumber = (userId: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(`/account/accounts/${userId}/reset-phone`)
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const resetPassword = (userId: string) => {
  return new Promise<string>((resolve, reject) => {
    MS_API.post<string>(`/account/accounts/${userId}/reset-password`)
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

type verifyUserInformationDTO = {
  cardNo: string;
  cardType: string;
  userId: string;
};

export const verifyUserInformation = (data: verifyUserInformationDTO) => {
  const { cardNo, cardType, userId } = data;
  const formData = new FormData();
  formData.append('cardNo', cardNo.trim());
  formData.append('cardType', cardType);
  formData.append('userId', userId);
  return new Promise<void>((resolve, reject) => {
    MS_API.post(stringifyUrl({ url: `${API_ENDPOINT.COMMON}/card/info` }), formData)
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const getImageOfUser = (userId: string) => {
  return new Promise<string>((resolve, reject) => {
    MS_API.get<string>(stringifyUrl({ url: `${API_ENDPOINT.COMMON}/identityCards`, query: { userId } }))

      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

type uploadImageDTO = {
  image: File;
  userId: string;
};
export const uploadImage = (data: uploadImageDTO) => {
  const { userId, image } = data;
  const formData = new FormData();
  formData.append('image', image);
  formData.append('userId', userId);
  return new Promise<string>((resolve, reject) => {
    MS_API.post(stringifyUrl({ url: API_ENDPOINT.COMMON }), formData)
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};
