import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../../ms-core/hook/use-modal';
import { copyToClipboard } from '../../../ms-core/utils/copy';
import { showToast } from '../../../ms-core/utils/toast';
import FormControl from '../FormControl';

export const GetContentModal: React.FC = () => {
  const { showModal, onHide } = useModal();

  const onClick = () => {
    showModal(<GetContentModalForm onHide={onHide} />, false);
  };

  return (
    <button type="button" className="button is-primary is-small" onClick={onClick}>
      <span className="icon">
        <i className="mdi mdi-content-paste" />
      </span>
      <span>
        <FormattedMessage id="Get Content" />
      </span>
    </button>
  );
};

type GetContentModalFormProps = {
  onHide: () => void;
};

const GetContentModalForm = (props: GetContentModalFormProps) => {
  const { onHide } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isCopySuccess, setIsCopySuccess] = useState<boolean>(true);
  const [content, setContent] = useState<string>();

  const onSubmit = (values: Record<string, unknown>) => {
    setIsLoading(true);

    setTimeout(() => {
      setContent(
        `${(values?.content as string)
          .replace(/\n/g, '')
          .replace(/"/g, "'")
          .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}`,
      );
      setIsLoading(false);
    }, 100);
  };

  return (
    <FormControl
      isLoading={isLoading}
      closeModal={onHide}
      conf={[
        {
          name: 'Content',
          field: 'content',
          type: 'editor',
        },
      ]}
      onSubmitData={onSubmit}
      title="Get content"
      renderMore={
        <div>
          {content ? (
            <>
              <div className="file has-name is-fullwidth is-right is-small">
                <label className="file-label">
                  <span className="file-cta">
                    <span className="file-label">
                      <span
                        className="icon-text is-clickable has-text-success"
                        onClick={async () => {
                          const result = await copyToClipboard(content);
                          if (result) {
                            setIsCopySuccess(true);
                            showToast.success('Copied');
                          } else {
                            setIsCopySuccess(false);
                            showToast.warning('Cannot copy, please copy manual!');
                          }
                        }}
                      >
                        <span className="icon is-primary">
                          <i className="mdi mdi-content-copy" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <span className="file-name">{content}</span>
                </label>
              </div>
              {!isCopySuccess && (
                <textarea
                  disabled
                  value={content}
                  className="textarea mt-2 has-text-weight-bold"
                  placeholder=""
                  rows={3}
                />
              )}
            </>
          ) : (
            <div className="file has-name is-fullwidth is-right is-small">
              <label className="file-label">
                <span className="file-cta">
                  <span className="file-label">
                    <span className="icon-text is-clickable has-text-success">
                      <span className="icon is-primary">
                        <i className="mdi mdi-content-copy" />
                      </span>
                    </span>
                  </span>
                </span>
                <span className="file-name" />
              </label>
            </div>
          )}
        </div>
      }
    />
  );
};
