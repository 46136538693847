import React from 'react';
import { Effect, LazyLoadImage } from 'react-lazy-load-image-component';
// import { FileService } from '../../utils/files/file.service';
import Skeleton from 'react-loading-skeleton';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';

type ImageControlProps = {
  src: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
  alt?: string;
  className?: string;
  effect?: Effect;
};

export const ImgServer = (props: ImageControlProps) => {
  const { src = '', isLoading = false, style, alt = '', className = '', effect = 'blur' } = props;

  return (
    <div className="img-server-wrapper">
      {isLoading ? (
        <Skeleton />
      ) : (
        <LazyLoadImage
          style={style}
          className={`${className} ${isLoading ? ' is-loading' : ''}`}
          src={src}
          // src={FileService.getInstance().buildURL(src)}
          alt={alt}
          effect={effect}
        />
      )}
    </div>
  );
};
