import { stringifyUrl } from 'query-string';
import { API_ENDPOINT } from '../../constants/api-endpoint';
import { MS_API } from '../../ms-core/utils/api';

export const adminUploadImage = (
  file: File,
  params: {
    fileName?: string;
    fileType?: 'image' | 'video';
    folderName?: string;
  },
) => {
  const { fileName, fileType } = params;

  /**
   * TODO: Handle folder name
   */

  const formData = new FormData();

  formData.append('file', file);

  return new Promise<string>((resolve, reject) => {
    MS_API.post(
      stringifyUrl(
        {
          url: API_ENDPOINT.ADMIN_UP_LOAD,
          query: {
            fileName,
            fileType,
          },
        },
        { skipEmptyString: true, skipNull: true },
      ),
      formData,
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });
};
