import React from 'react';
import { FormattedMessage } from 'react-intl';

const MsNoData = (props: { title?: string; errorCode?: boolean }) => {
  const { title = 'No data available please change the scope search...', errorCode = false } = props;
  return (
    <section className="section pt-2 pb-6">
      <div className="content has-text-grey has-text-centered">
        {/* <p>
          <span className="icon is-large">
            <i className="mdi mdi-timer-sand-empty mdi-48px" />
          </span>
        </p> */}
        <p>
          <FormattedMessage id={errorCode ? `error.${title}` : title} />
        </p>
      </div>
    </section>
  );
};

export default MsNoData;
