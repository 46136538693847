/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import FormControl from '../../../../../components/form-detail/FormControl';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { useConfirm } from '../../../../../ms-core/hook/use-confirm';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { showToast } from '../../../../../ms-core/utils/toast';
import { getLangUIConfig } from '../../../../../services/common/website-builders';
import { Select } from '../../component/select';
import { getAdminUIConfigById, updateAdminUIConfigById } from '../../../../../services/common/admin-builders';
import { IGeneralConfigProps } from './general-config.type';

export const GeneralConfig: React.FC<IGeneralConfigProps> = () => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);

  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [defaultData, setDefaultData] = useState<Record<string, any>>();

  const { showConfirm } = useConfirm();

  const changeDataFollowLang = async (lang: string) => {
    setLang(lang as LANGUAGE);
  };

  const fetchDataLang = () => {
    setIsLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchData = async (lang: string) => {
    setIsLoading(true);
    setTimeout(() => {
      getAdminUIConfigById(lang, 'general-config')
        .then((res) => {
          setDefaultData(res);
        })
        .catch(() => setDefaultData({}))
        .finally(() => setIsLoading(false));
    }, 100);
  };

  const onSubmit = (values: Record<string, any>) => {
    updateAdminUIConfigById({ ...defaultData, ...values }, lang, 'general-config').then(() => {
      showToast.success('Update Resource Success');
      fetchData(lang);
      window.localStorage.setItem('general-config', JSON.stringify(values));
    });
  };

  const onConfirmSubmit = (values: Record<string, any>) => {
    showConfirm('You have changes made. Would you like to save current progress?', 'Accept', {
      onConfirm: () => onSubmit(values),
    });
  };

  useEffect(() => {
    fetchDataLang();
  }, []);

  useEffect(() => {
    if (!lang) return;

    fetchData(lang);
  }, [lang]);

  return (
    <div className="static-content section">
      {!defaultData?.zeroConfigs?.listConf ? (
        <Skeleton width="100%" height="50vh" count={1} />
      ) : (
        <FormControl
          title={defaultData?.zeroConfigs?.title}
          renderRightHeader={() => (
            <a className="card-header-icon">
              <button className="is-small button is-ghost mr-0">
                {!!languageTypeSource && (
                  <Select
                    onChangeData={changeDataFollowLang}
                    defaultValue={lang}
                    name="lang"
                    options={languageTypeSource}
                    disableAllValue
                  />
                )}
              </button>
              <ModalUploadFile folderName="ui-config" isHasAccessPublic />
            </a>
          )}
          isLoading={isLoading}
          conf={[...defaultData?.zeroConfigs?.listConf]}
          defaultValues={defaultData}
          onSubmitData={onConfirmSubmit}
          maxHeight="100%"
        />
      )}
    </div>
  );
};
