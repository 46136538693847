import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import FormField from '../form-field-login/form-field';
import { useAuth } from '../../../../ms-core/utils/auth';
import { AxiosResponse } from 'axios';
import { RECAPTCHA_V2 } from '../../../../constants/platform';
import { FormattedMessage, useIntl } from 'react-intl';
import { showToast } from '../../../../ms-core/utils/toast';
import { ENABLE_CAPTCHA_LOGIN } from '../../../../utils/secrets';
import { IFormLoginProps } from './login.type';
import './form-login.style.scss';

export const L_EMAIL = '@email';
export const L_PASSWORD = '@password';

export const LoginForm: React.FC<IFormLoginProps> = (props) => {
  const { onAuth, onStatus } = props;

  const { signin } = useAuth();

  const [loading, setLoading] = useState(false);

  const [captcha, setCaptcha] = useState('');

  const [rememberMe, setRememberMe] = useState(true);

  const { formatMessage } = useIntl();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      email: localStorage.getItem(L_EMAIL) || '',
      password: localStorage.getItem(L_PASSWORD) || '',
      otp: undefined,
    },
  });

  // Handle form submission
  const onSubmit = ({ email, password, otp }: { email: string; password: string; otp: string }) => {
    if (!captcha && ENABLE_CAPTCHA_LOGIN) return showToast.warning('Please enter captcha!');

    setLoading(true);

    signin(email, password, captcha, otp)
      .then(() => {
        showToast.success('You have been successfully logged in');

        if (rememberMe) {
          localStorage.setItem(L_EMAIL, email);
          localStorage.setItem(L_PASSWORD, password);
        } else {
          localStorage.clear();
        }

        onAuth?.();
      })
      .catch((error: { response: AxiosResponse }) => {
        onStatus?.(error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormField
        name="email"
        type="text"
        label={formatMessage({ id: 'Username' })}
        placeholder={formatMessage({ id: 'Username' })}
        required
        error={errors.email}
        autoFocus
        inputRef={register({
          required: formatMessage({ id: 'Please enter an email' }),
        })}
      />

      <FormField
        name="password"
        type="password"
        label={formatMessage({ id: 'Password' })}
        placeholder={formatMessage({ id: 'Password' })}
        required
        error={errors.password}
        inputRef={register({
          required: formatMessage({ id: 'Please enter an password' }),
        })}
      />
      <div className="field is-grouped">
        <div className="control" style={{ flex: 1 }}>
          <label className="b-checkbox checkbox">
            <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
            <span className="check is-black" />
            <span className="control-label">
              &nbsp;
              <FormattedMessage id="Remember me" />
            </span>
          </label>
        </div>
        <div className="control">
          <Link to="/">
            <span className="control-label">
              <FormattedMessage id="Forgot Password?" />
            </span>
          </Link>
        </div>
      </div>
      {ENABLE_CAPTCHA_LOGIN && (
        <div className="control captcha-container">
          <ReCAPTCHA
            sitekey={RECAPTCHA_V2}
            onChange={(captcha) => setCaptcha(captcha || '')}
            //
          />
        </div>
      )}

      <hr />
      <div className="field">
        <button
          type="submit"
          className={`button is-black is-fullwidth${loading ? ' is-loading' : ''}`}
          disabled={loading || (ENABLE_CAPTCHA_LOGIN && !captcha)}
        >
          <FormattedMessage id="Login" />
        </button>
      </div>
    </form>
  );
};
