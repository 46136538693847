import { get } from 'lodash';
import moment from 'moment';

export const convertParams = (params: any) => {
  const data = { ...params };
  params.size && (data.limit = params.size);
  params.page && params.size && (data.offset = Number(params.size) * (Number(params.page) - 1));
  params.timeLoginFrom && (data.timeFrom = moment(get(params, 'timeLoginFrom')).unix());
  params.timeLoginTo && (data.timeTo = moment(get(params, 'timeLoginTo')).unix());
  params.timeFrom && (data.timeFrom = moment(get(params, 'timeFrom')).unix());
  params.timeTo && (data.timeTo = moment(get(params, 'timeTo')).unix());
  delete data.page;
  delete data.size;
  delete data.timeLoginFrom;
  delete data.timeLoginTo;
  return { ...data };
};

export const convertParamsCommon = (params: any) => {
  const data = { ...params };
  params.size && (data.limit = params.size);
  params.page && params.size && (data.offset = Number(params.size) * (Number(params.page) - 1));
  delete data.page;
  delete data.size;
  return { ...data };
};
