import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataType } from '../models/ms-type';
import { ValueOfTranslation } from '../types/status-source-type';

interface Props {
  field: string;
  name: string;
  customize?: React.ReactNode;
  className?: string;
  dataType?: DataType;
  sortable?: boolean;
  sortOrder?: 'DESC' | 'ASC';
  onSortChange?: (sort: string) => void;
  abbrTitle?: string;
  sortName?: string;
  valueOfTranslation?: ValueOfTranslation;
}

const MsCellHead = (props: Props) => {
  const {
    field,
    name,
    customize,
    className,
    dataType,
    sortable,
    sortOrder,
    onSortChange,
    abbrTitle,
    sortName,
    valueOfTranslation,
  } = props;

  const { formatMessage } = useIntl();

  const onClick = (): void => {
    if (sortable && onSortChange) {
      onSortChange(`${sortName || field},${sortOrder === 'ASC' ? 'DESC' : 'ASC'}`);
    }
  };

  let align: 'right' | 'left' | 'centered';
  switch (dataType) {
    case 'money':
    case 'number':
      align = 'right';
      break;
    case 'tag':
    case 'select':
    case 'status':
    case 'image':
    case 'imageService':
      align = 'centered';
      break;
    default:
      align = 'left';
      break;
  }

  return (
    <th
      className={`is-sortable has-text-${align} ${className}`}
      key={field}
      style={{ whiteSpace: 'nowrap' }}
      onClick={onClick}
    >
      <div className="th-wrap">
        {customize ||
          (abbrTitle ? (
            <abbr title={name ? formatMessage({ id: name }) : ''}>{abbrTitle}</abbr>
          ) : name ? (
            valueOfTranslation ? (
              <FormattedMessage id={props?.name ?? ''} values={props?.valueOfTranslation} />
            ) : (
              <FormattedMessage id={props?.name ?? ''} />
            )
          ) : (
            ''
          ))}
        {sortable && (
          <span className="icon is-mall">
            <i className={`mdi mdi-${!sortOrder ? 'menu-swap' : sortOrder === 'ASC' ? 'menu-up' : 'menu-down'}`} />
          </span>
        )}
      </div>
    </th>
  );
};

export default MsCellHead;
