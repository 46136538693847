/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import React, { useCallback } from 'react';
import Tabs from '../../../../../../../components/tab/Tab';
import TabPane from '../../../../../../../components/tab/TabPanel/tab-panel';
import { toTitleCase } from '../../../../../../../utils/helper';
import { SectionDynamic } from '../../section-dynamic.builder';
import { ArrayJson } from '../array-json';
import { ChildObjectJson } from './components';
import { IObjectJsonProps } from './object-json.type';

export const ObjectJson: React.FC<IObjectJsonProps> = (props) => {
  const {
    item,
    response,
    onUpdateData,
    isLoading,
    parentItem,
    parentConfigs,
    configs,
    onRefresh,
    lang,
    sectionId,
    nameKey,
  } = props;

  const onSubmitUpdateArrayData = useCallback(
    async (values: Record<string, any>, callBack?: () => void) => {
      await SectionDynamic.setDataSubmit(values);
      await onUpdateData(
        {
          ...values,
        },
        callBack,
      );
    },
    [onUpdateData],
  );

  const onSubmitUpdateObjectData = useCallback(
    (values: Record<string, any>, callBack?: () => void) => {
      const tmpData: Record<string, any> = {};
      if (configs?.nameKey) {
        if (Array.isArray(parentItem) && parentConfigs?.nameKey) {
          onSubmitUpdateArrayData(values, callBack);
        } else {
          _.set(tmpData, [configs?.nameKey], { ...item, ...values });
          onSubmitUpdateArrayData(tmpData, callBack);
        }
      } else {
        onSubmitUpdateArrayData({ ...response, ...values }, callBack);
      }
    },
    [configs?.nameKey, item, onSubmitUpdateArrayData, parentConfigs?.nameKey, parentItem, response],
  );

  const saveArrayData = useCallback(
    (values: Record<string, any>, callBack?: () => void) => {
      const tmpData: Record<string, any> = {};
      if (configs?.nameKey) {
        _.set(tmpData, [configs?.nameKey], { ...item, ...values });
        onSubmitUpdateArrayData(tmpData, callBack);
      } else {
        onSubmitUpdateArrayData(values, callBack);
      }
    },
    [configs?.nameKey, item, onSubmitUpdateArrayData],
  );

  return (
    <>
      {!!configs?.listConf && (
        <ChildObjectJson
          conf={configs.listConf}
          onUpdateData={onSubmitUpdateObjectData}
          isLoading={isLoading}
          response={response}
          nameKey={configs.nameKey}
          item={(nameKey ? _.get(parentItem, nameKey || '', {}) : parentItem) as Record<string, any>}
          title={configs?.title ?? toTitleCase(sectionId ?? '-', '-')}
          parentItem={parentItem}
          parentConfigs={configs}
        />
      )}
      <Tabs hiddenTabName={configs?.hiddenTabName ?? false}>
        {Array.isArray(configs?.configs) &&
          configs?.configs?.map((cfgChild: Record<string, any>, key: number) => {
            if (cfgChild.type === 'array') {
              return (
                <TabPane name={cfgChild.title} key={key + 1}>
                  <ArrayJson
                    sectionId={sectionId}
                    lang={lang}
                    configs={cfgChild}
                    onRefresh={onRefresh}
                    response={response}
                    items={_.get(item, cfgChild.nameKey || '', [])}
                    onUpdateData={saveArrayData}
                    isLoading={isLoading}
                    parentConfigs={configs}
                    parentItem={parentItem}
                    // dataOfObjectHasArray={item}
                  />
                </TabPane>
              );
            }
            return (
              <TabPane name={cfgChild.title} key={key + 1}>
                <ObjectJson
                  conf={cfgChild.listConf}
                  response={response}
                  nameKey={cfgChild.nameKey}
                  title={cfgChild?.title ?? toTitleCase(sectionId ?? '-', '-')}
                  configs={cfgChild}
                  item={_.get(item, cfgChild.nameKey || '', {}) as Record<string, any>}
                  onUpdateData={onSubmitUpdateObjectData}
                  isLoading={isLoading}
                  parentConfigs={configs}
                  parentItem={item}
                  sectionId={sectionId}
                  lang={lang}
                  onRefresh={onRefresh}
                />
              </TabPane>
            );
          })}
      </Tabs>
    </>
  );
};
