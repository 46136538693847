/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/display-name */
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import CustomSelect from '../../../../../components/custom-selection';
import { EditorControl } from '../../../../../components/editor';
import { HTTMLEditorControl } from '../../../../../components/html-editor-control';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import { EditorType, EditorTypeSource } from '../../../../../constants/types/ui-config-type';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { Input } from '../../../../../ms-core/blocks/controls';
import MsNoData from '../../../../../ms-core/blocks/ms-nodata';
import { useConfirm } from '../../../../../ms-core/hook/use-confirm';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { showToast } from '../../../../../ms-core/utils/toast';
import { getLangUIConfig, getUIConfigById, updateUIConfigById } from '../../../../../services/common/website-builders';
import { Select } from '../../components/select';

const StaticContent = memo(() => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);
  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, handleSubmit, reset, watch, errors, register, setValue } = useForm({ shouldUnregister: false });
  const { showConfirm } = useConfirm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  });

  const changeDataFollowLang = async (lang: string) => {
    setLang(lang as LANGUAGE);
  };

  const fetchDataLang = () => {
    setIsLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchData = async (lang: string) => {
    setIsLoading(true);
    setTimeout(() => {
      getUIConfigById(lang, 'static-pages-content')
        .then((res) => {
          res.data.forEach((e: { typeEditor: EditorType }) => {
            e.typeEditor = e.typeEditor ?? EditorType.WORD_EDITOR;
          });
          reset({
            data: res.data ?? [],
          });
        })
        .catch(() =>
          reset({
            data: [],
          }),
        )
        .finally(() => setIsLoading(false));
    }, 100);
  };

  const onSubmit = (values: Record<string, any>) => {
    updateUIConfigById(values, lang, 'static-pages-content').then(() => {
      showToast.success('Update Resource Success');
      fetchData(lang);
    });
  };

  const onConfirmSubmit = (values: Record<string, any>) => {
    showConfirm('You have changes made. Would you like to save current progress?', 'Accept', {
      onConfirm: () => onSubmit(values),
    });
  };

  useEffect(() => {
    fetchDataLang();
  }, []);

  useEffect(() => {
    if (!lang) return;

    fetchData(lang);
  }, [lang]);

  return (
    <div className="static-content section">
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-content-save-cog" />
                  </span>
                  <span>
                    <FormattedMessage id="Static content" />
                  </span>
                </p>
                <a className="card-header-icon">
                  <button className="is-small button is-ghost mr-0">
                    {!!languageTypeSource && (
                      <Select
                        onChangeData={changeDataFollowLang}
                        defaultValue={lang}
                        name="lang"
                        options={languageTypeSource}
                        disableAllValue
                      />
                    )}
                  </button>

                  <ModalUploadFile folderName="ui-config" isHasAccessPublic />

                  {isLoading ? (
                    <Skeleton count={1} />
                  ) : (
                    <button
                      onClick={() => {
                        append({
                          id: 'New id',
                          title: 'New title',
                          content: '<div>New content</div>',
                          typeEditor: EditorType.WORD_EDITOR,
                        });
                        window.scrollTo(0, 9999);
                      }}
                      type="button"
                      className="button is-primary is-small"
                    >
                      <span className="icon">
                        <i className="mdi mdi-plus default" />
                      </span>
                      <span>
                        <FormattedMessage id="Create" />
                      </span>
                    </button>
                  )}

                  {isLoading ? (
                    <Skeleton count={1} />
                  ) : (
                    <button onClick={() => fetchData(lang)} type="button" className="button is-small">
                      <span className="icon">
                        <i className="mdi mdi-refresh default" />
                      </span>
                      <span>
                        <FormattedMessage id="Refresh data" />
                      </span>
                    </button>
                  )}
                </a>
              </header>

              <div className="card-content pt-0">
                <div className="content">
                  {!_.isEmpty(fields) && !!fields ? (
                    <div className="static-content">
                      <form onSubmit={handleSubmit(onConfirmSubmit)}>
                        {fields.map((item, index) => {
                          return (
                            <>
                              <div key={item.id} className="field p-5 is-border">
                                <div className="field-label is-normal has-text-left pb-4	">
                                  <div className="label">
                                    {isLoading ? <Skeleton count={1} /> : watch(`data.${index}.title`)}
                                  </div>
                                </div>
                                <div className="field-body">
                                  <div className="field">
                                    <div className="control">
                                      <div className="info-description">
                                        <div className="field pt-2">
                                          <label className="label">
                                            <div className="level">
                                              <div className="level-left">
                                                <div className="level-item">
                                                  {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="ID" />}
                                                </div>
                                              </div>
                                              <div className="level-right">
                                                <div className="level-item">
                                                  {isLoading ? (
                                                    <Skeleton count={1} />
                                                  ) : (
                                                    <button
                                                      onClick={() => remove(index)}
                                                      type="button"
                                                      className="button is-danger is-small ml-2"
                                                    >
                                                      <span className="icon">
                                                        <i className="mdi mdi-delete-alert default" />
                                                      </span>
                                                      <span>
                                                        <FormattedMessage id="Remove" />
                                                      </span>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </label>

                                          <div className="control">
                                            {isLoading ? (
                                              <Skeleton width="100%" count={1} />
                                            ) : (
                                              <Input
                                                defaultValue={watch(`data.${index}.id`)}
                                                className={`input${errors?.[`data.${index}.id`] ? ' is-danger' : ''}`}
                                                name={`data.${index}.id`}
                                                control={control}
                                                rules={{
                                                  required: {
                                                    value: true,
                                                    message: 'This field is required!',
                                                  },
                                                }}
                                                placeholder="ID"
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="field pt-2">
                                          <label className="label">
                                            <div className="level">
                                              <div className="level-left">
                                                <div className="level-item">
                                                  {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Name" />}
                                                </div>
                                              </div>
                                            </div>
                                          </label>

                                          <div className="control">
                                            {isLoading ? (
                                              <Skeleton width="100%" count={1} />
                                            ) : (
                                              <Input
                                                defaultValue={watch(`data.${index}.title`)}
                                                className={`input${
                                                  errors?.[`data.${index}.title`] ? ' is-danger' : ''
                                                }`}
                                                name={`data.${index}.title`}
                                                control={control}
                                                rules={{
                                                  required: {
                                                    value: true,
                                                    message: 'This field is required!',
                                                  },
                                                }}
                                                placeholder="Title"
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="field pt-2">
                                          <label className="label">
                                            <div className="level">
                                              <div className="level-left">
                                                <div className="level-item">
                                                  {isLoading ? (
                                                    <Skeleton count={1} />
                                                  ) : (
                                                    <FormattedMessage id="Type editor" />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </label>
                                          <div className="control">
                                            {isLoading ? (
                                              <Skeleton width="100%" count={1} />
                                            ) : (
                                              <CustomSelect
                                                name={`data.${index}.typeEditor`}
                                                options={EditorTypeSource}
                                                placeholder="Type"
                                                defaultValue={watch(`data.${index}.typeEditor`)}
                                                control={control}
                                                rules={{
                                                  required: {
                                                    value: true,
                                                    message: 'This field is required!',
                                                  },
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        {watch(`data.${index}.typeEditor`) && (
                                          <div className="field pt-2">
                                            <label className="label">
                                              <div className="level">
                                                <div className="level-left">
                                                  <div className="level-item">
                                                    {isLoading ? (
                                                      <Skeleton count={1} />
                                                    ) : (
                                                      <FormattedMessage id="Content" />
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </label>
                                            <div className="control">
                                              {isLoading ? (
                                                <Skeleton width="100%" height="50vh" count={1} />
                                              ) : (
                                                <>
                                                  {watch(`data.${index}.typeEditor`) === EditorType.WORD_EDITOR && (
                                                    <EditorControl
                                                      name={`data.${index}.content`}
                                                      control={control}
                                                      defaultValue={watch(`data.${index}.content`)}
                                                    />
                                                  )}
                                                </>
                                              )}

                                              {isLoading ? (
                                                <Skeleton width="100%" height="50vh" count={1} />
                                              ) : (
                                                <>
                                                  {watch(`data.${index}.typeEditor`) === EditorType.HTML_EDITOR && (
                                                    <HTTMLEditorControl
                                                      defaultValue={watch(`data.${index}.content`)}
                                                      rules={{
                                                        required: {
                                                          value: true,
                                                          message: 'This field is required!',
                                                        },
                                                      }}
                                                      name={`data.${index}.content`}
                                                      control={control}
                                                      width="100%"
                                                      height="65vh"
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </>
                          );
                        })}
                        <div className="field">
                          <div className="field-label is-normal" />
                          <div className="field-body">
                            <div className="field">
                              {isLoading ? (
                                <Skeleton count={1} />
                              ) : (
                                <button type="submit" onClick={onConfirmSubmit} className="button is-primary">
                                  <span>
                                    <FormattedMessage id="Submit" />
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <MsNoData />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StaticContent;
