import React from 'react';
import { IChildObjectJsonProps } from './child-object-json.type';
import FormControl from '../../../../../../../../components/form-detail/FormControl';
import { showToast } from '../../../../../../../../ms-core/utils/toast';

export const ChildObjectJson: React.FC<IChildObjectJsonProps> = (props) => {
  const { item, conf, onUpdateData, isLoading, title } = props;

  const onSubmit = (values: Record<string, any>) => {
    if (values.id !== item?.id) {
      showToast.error('Invalid id');
    } else {
      onUpdateData({
        ...values,
      });
    }
  };

  return (
    <FormControl
      isLoading={isLoading}
      title={title}
      conf={conf}
      defaultValues={item}
      onSubmitData={onSubmit}
      maxHeight="100%"
      icon={`alpha-${title?.split?.('')?.[0]?.toLowerCase()}-circle`}
    />
  );
};
