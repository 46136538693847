import React from 'react';

interface Props {
  /**
   * Set or retrieves the name of the object.
   */
  name: string;
  /**
   * Return content type of the object.
   */
  type: string;
  /**
   * When present, marks an element that can't be submitted without a value.
   */
  required: boolean;
  /**
   * Gets or sets a text string that is displayed in an input field as a hint or
   * prompt to users as the format or type of information they need to enter.
   * The text appears in an input filed until the user puts focus ont he field.
   */
  placeholder: string;
  /**
   * Gets or sets a text string that is displayed in the label content.
   */
  label: string;
  /**
   * Way to access input element created in the render method, imperatively
   * modify a child outside of the typical flow as Props child-parent.
   */
  inputRef: any;
  autoFocus?: boolean;
  error: any;
}

const FormField: React.FunctionComponent<Props> = (props: Props) => {
  const { error, type, label, inputRef, ...inputProps } = props;

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        {type === 'textarea' && <textarea className="textarea is-medium" ref={inputRef} {...inputProps} />}

        {type !== 'textarea' && (
          <input
            autoCorrect="false"
            autoComplete="false"
            className="input"
            ref={inputRef}
            type={type}
            {...inputProps}
          />
        )}
      </div>

      {error && <p className="help is-danger">{error.message}</p>}
    </div>
  );
};

export default FormField;
