import { LoginDto } from '../../ms-core/utils/dto/login-dto';

type ILoginParams = {
  password?: string;
  username?: string;
  captcha?: string;
  otp?: string;
};

// export const loginApi = (data: ILoginParams) => {
//   return new Promise<Record<string, any>>((resolve, reject) => {
//     MS_API_LOGIN.post<Record<string, any>>(stringifyUrl({ url: '/account/signin' }), {
//       username: data.username,
//       password: data.password,
//     })
//       .then((res) => resolve(res?.data))
//       .catch(() => reject());
//   });
// };

export const loginApi = (params: ILoginParams): Promise<LoginDto> => {
  const { password, username } = params;

  return new Promise((resolve, reject) => {
    fetch(`https://api-web.cybertech.com.vn/account/signin`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        username,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
