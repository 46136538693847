import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Route, Router, ProvideMsRouter } from './ms-core/utils/routers';
import { store } from './redux/storage';
import { RouteProps, Switch } from 'react-router';
import { ProvideAuth } from './ms-core/utils/auth';
import { ConfirmProvider } from './ms-core/hook/use-confirm';
import { ModalProvider } from './ms-core/hook/use-modal';
import { useLang } from './hook/use-lang';
import { IntlProvider } from 'react-intl';
import { LoginPage } from './pages';
import { Layout } from './layouts';
import { DEFAULT_TIMEZONE } from './utils/date-format';
import { IAppProps } from './app.type';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/main.scss';
import './app.style.scss';

moment.tz.setDefault(DEFAULT_TIMEZONE.VN);

export const App: React.FC<IAppProps> = (props) => {
  const { messages } = props;

  const render = (ps: RouteProps) => <Layout {...ps} />;

  const lang = useLang();

  return (
    <IntlProvider messages={messages[lang.local]} locale={lang.local}>
      <ProvideAuth>
        <ProvideMsRouter>
          <ConfirmProvider>
            <Provider store={store}>
              <Router>
                <ModalProvider>
                  <Switch>
                    <Route exact path="/auth/signin" component={LoginPage} />
                    <Route path="/" render={render} />
                  </Switch>
                  <ToastContainer autoClose={2000} />
                </ModalProvider>
              </Router>
            </Provider>
          </ConfirmProvider>
        </ProvideMsRouter>
      </ProvideAuth>
    </IntlProvider>
  );
};

// import React, { lazy, Suspense } from 'react';
// import moment from 'moment';
// import 'moment-timezone';
// import { Provider } from 'react-redux';
// import { ToastContainer } from 'react-toastify';
// import { Route, Router, ProvideMsRouter } from './ms-core/utils/routers';
// import { store } from './redux/storage';
// import { RouteProps, Switch } from 'react-router';
// import { ProvideAuth } from './ms-core/utils/auth';
// import { ConfirmProvider } from './ms-core/hook/use-confirm';
// import { ModalProvider } from './ms-core/hook/use-modal';
// import { useLang } from './hook/use-lang';
// import { IntlProvider } from 'react-intl';
// import { DEFAULT_TIMEZONE } from './utils/date-format';
// import { IAppProps } from './app.type';
// import 'react-toastify/dist/ReactToastify.css';
// import './assets/scss/main.scss';
// import './app.style.scss';

// moment.tz.setDefault(DEFAULT_TIMEZONE.VN);

// const LoginPage = lazy(() => import('./pages/login-page'));
// const Layout = lazy(() => import('./layouts'));

// export const App: React.FC<IAppProps> = (props) => {
//   const { messages } = props;

//   const render = (ps: RouteProps) => (
//     <Suspense fallback={<div>Loading...</div>}>
//       <Layout {...ps} />
//     </Suspense>
//   );

//   const lang = useLang();

//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       <IntlProvider messages={messages[lang.local]} locale={lang.local}>
//         <ProvideAuth>
//           <ProvideMsRouter>
//             <ConfirmProvider>
//               <Provider store={store}>
//                 <Router>
//                   <ModalProvider>
//                     <Switch>
//                       <Route exact path="/auth/signin" component={LoginPage} />
//                       <Route path="/" render={render} />
//                     </Switch>
//                     <ToastContainer autoClose={2000} />
//                   </ModalProvider>
//                 </Router>
//               </Provider>
//             </ConfirmProvider>
//           </ProvideMsRouter>
//         </ProvideAuth>
//       </IntlProvider>
//     </Suspense>
//   );
// };
