import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ModalUploadFileForm } from '../../../../../components/modal-upload-image';
import { Input } from '../../../../../ms-core/blocks/controls';

export const StaticAssetsPage = () => {
  const { control, watch, errors } = useForm();

  return (
    <div className="static-assets section">
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-content-save-cog" />
                  </span>
                  <span>
                    <FormattedMessage id="Static assets" />
                  </span>
                </p>
              </header>

              <div className="card-content pt-0">
                <div className="content">
                  <div className="static-assets">
                    <div className="field is-horizontal p-5 is-border">
                      <div className="field-label is-normal has-text-left pb-4	">
                        <div className="label">
                          <FormattedMessage id="Upload file static" />
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <div className="info-description">
                              {/* <div className="field pt-2">
                                <label className="label">
                                  <div className="level">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <FormattedMessage id="Folder name" />
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="control">
                                  <Input
                                    className={`input${errors?.[`folderName`] ? ' is-danger' : ''}`}
                                    name={`folderName`}
                                    control={control}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: 'This field is required!',
                                      },
                                    }}
                                    placeholder="Folder name"
                                  />
                                </div>
                              </div> */}

                              <div className="field pt-2">
                                <label className="label">
                                  <div className="level">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <FormattedMessage id="File name" />
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="control">
                                  <Input
                                    className={`input${errors?.fileName ? ' is-danger' : ''}`}
                                    name="fileName"
                                    control={control}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: 'This field is required!',
                                      },
                                    }}
                                    placeholder="File name"
                                  />
                                </div>
                              </div>

                              {watch('fileName') && (
                                <div className="field pt-2">
                                  <label className="label">
                                    <div className="level">
                                      <div className="level-left">
                                        <div className="level-item">
                                          <FormattedMessage id="File upload" />
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="control">
                                    <ModalUploadFileForm
                                      // folder={watch('folderName')}
                                      folder="default"
                                      isHasAccessPublic
                                      isHasCard={false}
                                      fileName={watch('fileName')}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal p-5 is-border">
                      <div className="field-label is-normal has-text-left pb-4	">
                        <div className="label">
                          <FormattedMessage id="Static image assets" />
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <div className="info-description">
                              <div className="field pt-2">
                                <label className="label">
                                  <div className="level">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <FormattedMessage id="Logo text" />
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="control">
                                  <ModalUploadFileForm
                                    folder="logo"
                                    isHasAccessPublic
                                    isHasCard={false}
                                    defaultValue="/cbtcdn/logo-text.png"
                                    fileName="logo-text.png"
                                  />
                                </div>
                              </div>

                              <div className="field pt-2">
                                <label className="label">
                                  <div className="level">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <FormattedMessage id="Logo" />
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="control">
                                  <ModalUploadFileForm
                                    folder="logo"
                                    isHasAccessPublic
                                    isHasCard={false}
                                    defaultValue="/cbtcdn/logo.png"
                                    fileName="logo.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="field is-horizontal p-5 is-border">
                      <div className="field-label is-normal has-text-left pb-4	">
                        <div className="label">
                          <FormattedMessage id="Static download app" />
                        </div>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <div className="info-description">
                              <div className="field pt-2">
                                <label className="label">
                                  <div className="level">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <FormattedMessage id="Android" />
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="control">
                                  <>
                                    <ModalUploadFileForm
                                      folder="download"
                                      isHasAccessPublic
                                      isHasCard={false}
                                      defaultValue="/download/android.apk"
                                      fileName="android.apk"
                                      type={['application/vnd.android.package-archive']}
                                      size={41943040}
                                    />
                                  </>
                                </div>
                              </div>

                              <div className="field pt-2">
                                <label className="label">
                                  <div className="level">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <FormattedMessage id="Ios" />
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="control">
                                  <>
                                    <ModalUploadFileForm
                                      folder="download"
                                      isHasAccessPublic
                                      isHasCard={false}
                                      defaultValue="/download/ios.apk"
                                      fileName="ios.apk"
                                      type={['application/vnd.android.package-archive']}
                                      size={41943040}
                                    />
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
