/* eslint-disable react/function-component-definition */
import React from 'react';
import { Color } from '../types/color';

type Props = {
  children: React.ReactNode;
  color?: Color;
  className?: string;
};

export default function Tag({ children, color, className }: Props) {
  return <span className={`tag is-${color} ${className ?? ''}`}>{children}</span>;
}
