import React from 'react';

// About
const aboutPage = React.lazy(() => import('./pages/about'));
/**
 * * Account resource
 */
const websiteBuildersUiConfig = React.lazy(() => import('./pages/sections/src/pages/website-builders'));
const welcomePopupUiConfig = React.lazy(() => import('./pages/sections/src/pages/welcome-popup'));
const staticContent = React.lazy(() => import('./pages/sections/src/pages/static-content'));
const welcomePopupWui = React.lazy(() => import('./pages/sections/src/pages/welcome-popup-wui'));
const staticAssets = React.lazy(() => import('./pages/sections/src/pages/static-assets'));
const superVip = React.lazy(() => import('./pages/sections/src/pages/super-vip'));
const websiteBuildersCoreFormData = React.lazy(() => import('./pages/sections/src/pages/section-dynamic'));

// ADMIN CONFIGS
const adminBuildersUiConfig = React.lazy(() => import('./pages/admin-sections/src/pages/admin-section'));
const adminGeneralConfig = React.lazy(() => import('./pages/admin-sections/src/pages/general-config'));

// USER LIST
const userList = React.lazy(() => import('./pages/users'));
const overviewPage = React.lazy(() => import('./pages/overview'));
const analysisPage = React.lazy(() => import('./pages/analysis'));
const trafficInsightsPage = React.lazy(() => import('./pages/traffic-insights'));
const attackInsightsPage = React.lazy(() => import('./pages/attack-insights'));
const applicationPage = React.lazy(() => import('./pages/application'));
const allowRulePage = React.lazy(() => import('./pages/allow-rule'));
const blockRule = React.lazy(() => import('./pages/block-rule'));
const billing = React.lazy(() => import('./pages/billing'));

const appRoutes = [
  { path: '/about', exact: true, name: 'About', component: aboutPage },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: overviewPage },
  { path: '/analysis', exact: true, name: 'Analysis', component: analysisPage },
  { path: '/traffic-insights', exact: true, name: 'Traffic Insights', component: trafficInsightsPage },
  { path: '/attack-insights', exact: true, name: 'Attack Insights', component: attackInsightsPage },
  { path: '/application', exact: true, name: 'application', component: applicationPage },
  { path: '/allow-rule', exact: true, name: 'Allow rule', component: allowRulePage },
  { path: '/block-rule', exact: true, name: 'Block rule', component: blockRule },
  { path: '/billing', exact: true, name: 'Billing', component: billing },
  {
    path: '/ui-config/website-builders',
    exact: true,
    name: 'UI Config',
    component: websiteBuildersUiConfig,
  },
  {
    path: '/ui-config/website-builders/welcome-popup',
    exact: true,
    name: 'Popup App',
    component: welcomePopupUiConfig,
  },
  {
    path: '/ui-config/website-builders/welcome-popup-wui',
    exact: true,
    name: 'Popup Website',
    component: welcomePopupWui,
  },
  {
    path: '/ui-config/website-builders/static-content',
    exact: true,
    name: 'Static content',
    component: staticContent,
  },
  {
    path: '/ui-config/website-builders/static-assets',
    exact: true,
    name: 'Static assets',
    component: staticAssets,
  },
  {
    path: '/ui-config/website-builders/super-vip',
    exact: true,
    name: 'Super vip',
    component: superVip,
  },
  {
    path: '/ui-config/website-builders/:id',
    exact: false,
    name: 'Page content',
    component: websiteBuildersCoreFormData,
  },

  {
    path: '/admin-config/admin-builders',
    exact: true,
    name: 'Admin Config',
    component: adminBuildersUiConfig,
  },

  {
    path: '/admin-config/admin-general-config',
    exact: true,
    name: 'General Config',
    component: adminGeneralConfig,
  },
];

export default appRoutes;
