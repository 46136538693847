import React, { useEffect, useState } from 'react';
import './index.scss';

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  colSpan: number;
  queryParam: Record<string, unknown>;
};
export const TableCollapse = (props: Props) => {
  const { title, children, colSpan, queryParam } = props;

  const [expanded, setExpanded] = useState(false);

  const toggleCardState = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false);
  }, [queryParam]);

  return (
    <>
      <tr draggable={false} className="table-collapse">
        <td className="has-text-weight-bold is-unset-max-width is-narrow has-text-centered">
          <span className="icon is-clickable" onClick={toggleCardState}>
            <i className={`mdi mdi-${!expanded ? 'chevron-down' : 'chevron-up'}`} />
          </span>
        </td>
        {title}
      </tr>
      <tr className="table-collapse--content">
        {expanded && (
          <td
            colSpan={colSpan + 2}
            style={{ display: !expanded ? 'none' : '' }}
            className="is-unset-max-width is-narrow"
          >
            {children}
          </td>
        )}
      </tr>
    </>
  );
};
