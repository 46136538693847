/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
// import { Link } from 'react-router-dom';
import { MultipleLanguageImg } from '../../assets/images';
import { ChangePasswordForm } from '../../components/admins/change-password';
import { useLang, LANGUAGE } from '../../hook/use-lang';
import { useModal } from '../../ms-core/hook/use-modal';
import { useAuth } from '../../ms-core/utils/auth';
import { L_EMAIL, L_PASSWORD } from '../../pages/login-page/components';
import { IHeaderProps } from './header.type';
import './header.style.scss';

export const Header: React.FC<IHeaderProps> = () => {
  const [isShowDropdownLanguage, setShowDropdownLanguage] = useState(false);

  const [isShowDropdownLogout, setShowDropdownLogout] = useState(false);

  const { signOut, staff } = useAuth();

  const { formatMessage } = useIntl();

  const { setLanguage, local } = useLang();

  const { showModal, onHide } = useModal();

  const translationList = useMemo(
    () => [
      {
        value: LANGUAGE.VI,
        text: 'Tiếng Việt',
        handler: setLanguage,
        icon: MultipleLanguageImg[LANGUAGE.VI].image,
      },
      {
        value: LANGUAGE.EN,
        text: 'English',
        handler: setLanguage,
        icon: MultipleLanguageImg[LANGUAGE.EN].image,
      },
      {
        value: LANGUAGE.ZH,
        text: '中国人',
        handler: setLanguage,
        icon: MultipleLanguageImg[LANGUAGE.ZH].image,
      },
      {
        value: LANGUAGE.TH,
        text: 'ไทย',
        handler: setLanguage,
        icon: MultipleLanguageImg[LANGUAGE.TH].image,
      },
      {
        value: LANGUAGE.ID,
        text: 'bahasa Indo',
        handler: setLanguage,
        icon: MultipleLanguageImg[LANGUAGE.ID].image,
      },
    ],
    [setLanguage],
  );

  const activeLang = useMemo(() => _.find(translationList, { value: local }), [local, translationList]);

  const handleLogout = useCallback(() => {
    if (window.confirm(formatMessage({ id: 'Please confirm you want to logout?' }))) {
      const email = localStorage.getItem(L_EMAIL);
      const password = localStorage.getItem(L_PASSWORD);

      const incomeBonusComm = localStorage.getItem('tt_incomeBonus');

      localStorage.clear();
      sessionStorage.clear();

      if (email && password) {
        localStorage.setItem(L_EMAIL, email);
        localStorage.setItem(L_PASSWORD, password);
      }
      if (incomeBonusComm) {
        localStorage.setItem('tt_incomeBonus', incomeBonusComm);
      }
      signOut();
    }
  }, [formatMessage, signOut]);

  const handleAsideMobileToggle: React.ComponentProps<'a'>['onClick'] = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      const t = event.currentTarget.getElementsByClassName('icon')[0].getElementsByClassName('mdi')[0];

      document.documentElement.classList.toggle('has-aside-mobile-expanded');
      document.documentElement.classList.toggle('has-aside-expanded');

      t.classList.toggle('mdi-forwardburger');
      t.classList.toggle('mdi-backburger');
    },
    [],
  );

  const onClickShowModalChangePassword = useCallback(() => {
    staff &&
      showModal(<ChangePasswordForm data={{ username: staff?.nickname, id: staff.sub }} onHide={onHide} isAdminOnly />);
  }, [onHide, showModal, staff]);

  return (
    <nav id="navbar-main" className="header-wrapper navbar is-fixed-top header-wrapper--background">
      <div className="navbar-brand">
        <a
          className="navbar-item jb-aside-mobile-toggle"
          onClick={handleAsideMobileToggle}
          role="menuitem"
          tabIndex={0}
        >
          <span className="icon">
            <i className="mdi mdi-backburger mdi-24px" />
          </span>
        </a>
      </div>
      <div className="navbar-menu fadeIn animated faster" id="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img alt={staff?.email?.split('@')[0]} src={staff?.picture} />
              </div>
              <div className="is-user-name">
                <span>{staff?.email?.split('@')[0]}</span>
              </div>
            </a>
          </div>
          {/* <Link to="/" title="Home" className="navbar-item has-divider is-desktop-icon-only">
            <span className="icon">
              <i className="mdi mdi-account-reactivate" />
            </span>
            <span className="tag is-white with-mark">{0}</span>
          </Link> */}

          {/* <Link
            to="/payment/deposit"
            className="navbar-item has-divider is-desktop-icon-only jb-aside-right-toggle has-tooltip-bottom has-tooltip-info"
            data-tooltip={formatMessage({ id: '+{count} New TopUp(s)' }, { count: 0 })}>
            <span className="icon has-update-mark">
              <i className="mdi mdi-alpha-t-circle default" />
            </span>
            <span className="tag is-info with-mark">{0}</span>
          </Link> */}

          {/* <Link
            to="/payment/withdrawal"
            className="navbar-item has-divider is-desktop-icon-only jb-aside-right-toggle has-tooltip-bottom has-tooltip-warning"
            data-tooltip={formatMessage({ id: '+{count} New Withdrawal(s)' }, { count: 0 })}>
            <span className="icon has-update-mark">
              <i className="mdi mdi-alpha-w-circle default" />
            </span>
            <span className="tag is-warning with-mark">{0}</span>
          </Link> */}
          <div className="dropdown is-right">
            <div className="dropdown-trigger navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
              <a className="navbar-link is-arrowless" onClick={() => setShowDropdownLanguage(!isShowDropdownLanguage)}>
                <div className="is-user-avatar">
                  <img alt={activeLang?.text} src={activeLang?.icon} style={{ objectFit: 'contain' }} />
                </div>
                <div className="is-user-name">
                  <span>{activeLang?.text}</span>
                </div>
              </a>
            </div>

            <div
              className="dropdown-menu"
              id="dropdown-menu3"
              role="menu"
              style={{ display: !isShowDropdownLanguage ? 'none' : 'block' }}
            >
              <div className="dropdown-content" onClick={() => setShowDropdownLanguage(!isShowDropdownLanguage)}>
                {translationList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable"
                    >
                      <a className="dropdown-item navbar-link is-arrowless" onClick={() => setLanguage(item.value)}>
                        <figure className="is-user-avatar">
                          <img src={item?.icon} alt={item?.text} style={{ objectFit: 'contain' }} />
                        </figure>
                        <div className="is-user-name is-black">
                          <span>{item?.text}</span>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="dropdown is-right">
            <div className="dropdown-trigger navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
              <a className="navbar-link is-arrowless pr-0" onClick={() => setShowDropdownLogout(!isShowDropdownLogout)}>
                <span className="icon">
                  <i className="mdi mdi-24px mdi-arrow-down-drop-circle-outline" />
                </span>
              </a>
            </div>

            <div
              className="dropdown-menu"
              id="dropdown-menu3"
              role="menu"
              style={{ display: !isShowDropdownLogout ? 'none' : 'block' }}
            >
              <div className="dropdown-content" onClick={() => setShowDropdownLogout(!isShowDropdownLogout)}>
                <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
                  <a className="dropdown-item navbar-link is-arrowless" onClick={onClickShowModalChangePassword}>
                    <span className="icon">
                      <i className="mdi mdi-18px mdi-lock-reset is-black" />
                    </span>
                    <div className="is-user-name is-black">
                      <span>{formatMessage({ id: 'Change password' })}</span>
                    </div>
                  </a>
                </div>
                <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
                  <a className="dropdown-item navbar-link is-arrowless" onClick={handleLogout}>
                    <span className="icon">
                      <i className="mdi mdi-logout mdi-18px is-black" />
                    </span>
                    <div className="is-user-name is-black">
                      <span>{formatMessage({ id: 'Log out' })}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
