import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { bootstrapApplication } from './app.bootstrap';

bootstrapApplication('en').then((app) => {
  const rootElement = document.getElementById('root') as HTMLElement;
  const root = createRoot(rootElement);
  root.render(app);
});

reportWebVitals();
