import { get, keys } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultipleSelectWithDataSource } from '../../../components/multiple-selection/multiple-select-with-data-source';
import { ToolType } from '../../models/ms-type';
import { showToast } from '../../utils/toast';
import DateRangePicker from './date-range-picker';
import './index.scss';
import Input from './input';
import { InputRange } from './input-range';
import { MultipleSelect } from './multiple-select';
import { Select } from './select';
import { SearchSelect, SelectInputChange } from './tool-select';

interface Props {
  /**
   * Specific search can use to refine the search results of a particular
   * category listing.
   */
  toolConf: ToolType[];
  /**
   * On event change value of any controls in level search
   */
  onSearchChanged: (obj?: Record<string, unknown>, isForceUpdate?: boolean) => void;
  /**
   * Search condition match with application state
   */
  queryParam: Record<string, unknown>;
  /**
   * Clear all the search conditions and fetch resource from API
   */
  refreshSearchCriteria: () => void;
  children?: React.ReactNode;
  customActions?: React.ReactNode;

  disableResetButton?: boolean;
  disableSearchButton?: boolean;
}

const MsToolPanel = (props: Props) => {
  const {
    toolConf,
    onSearchChanged,
    queryParam,
    refreshSearchCriteria,
    children,
    customActions,
    disableResetButton,
    disableSearchButton,
  } = props;
  const { register, getValues, setValue, handleSubmit, control } = useForm();

  const { formatMessage } = useIntl();

  const handleRemoveTag = (names: string[]) => {
    const searchParam = { ...queryParam };
    names.forEach((tagName) => delete searchParam[tagName]);
    onSearchChanged(searchParam, true);
  };

  const submitSearch = () => {
    const data = { ...queryParam, ...getValues() };

    const namesRequired = toolConf.reduce<string[]>((p, c) => {
      if (c.required) {
        p.push(...c.names);
      }
      return p;
    }, []);

    for (const name of namesRequired) {
      if (data[name] == null || data[name] === '') {
        showToast.warn('Invalid search!');
        return;
      }
    }

    keys(data).forEach((key) => {
      data[key] = data[key]?.trim?.();
      if (data[key] === '') delete data[key];
    });

    onSearchChanged(data, true);
  };

  const handleFormSubmission = () => {
    submitSearch();
  };

  const getQueryParamValue = (field: string) => {
    return get(queryParam, field);
  };

  const renderSearchElement = (item: ToolType, index: number): React.ReactNode => {
    if (item.customSelection) {
      return (
        <React.Fragment key={item.names.join()}>
          {item.customSelection(register, handleRemoveTag, submitSearch, setValue, queryParam)}
        </React.Fragment>
      );
    }
    switch (item.type) {
      case 'dateRange':
        if (item.names.length < 2) {
          throw new Error(
            formatMessage({ id: 'Please use string[] for tokenFiltered config when type is "dateRange"' }),
          );
        }

        return (
          <DateRangePicker
            key={item.names.join()}
            classNameColumn={item.classNameColumn}
            conf={item}
            onRemoveTag={handleRemoveTag}
            setValue={setValue}
            value={[getQueryParamValue(item.names[0]) as string, getQueryParamValue(item.names[1]) as string]}
            inputRef={register}
            submitChange={submitSearch}
            placeholder={item.placeholder}
            preset={item.preset}
            useDefaultPreset={item.useDefaultPreset ?? true}
          />
        );

      case 'dateRangeNotToDay':
        if (item.names.length < 2) {
          throw new Error(
            formatMessage({ id: 'Please use string[] for tokenFiltered config when type is "dateRange"' }),
          );
        }

        return (
          <DateRangePicker
            key={item.names.join()}
            classNameColumn={item.classNameColumn}
            conf={item}
            onRemoveTag={handleRemoveTag}
            setValue={setValue}
            value={[getQueryParamValue(item.names[0]) as string, getQueryParamValue(item.names[1]) as string]}
            inputRef={register}
            submitChange={submitSearch}
            placeholder={item.placeholder}
            preset={item.preset}
            useDefaultPreset={item.useDefaultPreset ?? true}
            type="isNotToday"
          />
        );

      case 'dateRangeOldData':
        if (item.names.length < 2) {
          throw new Error(
            formatMessage({ id: 'Please use string[] for tokenFiltered config when type is "dateRange"' }),
          );
        }

        return (
          <DateRangePicker
            key={item.names.join()}
            classNameColumn={item.classNameColumn}
            conf={item}
            onRemoveTag={handleRemoveTag}
            setValue={setValue}
            value={[getQueryParamValue(item.names[0]) as string, getQueryParamValue(item.names[1]) as string]}
            inputRef={register}
            submitChange={submitSearch}
            placeholder={item.placeholder}
            preset={item.preset}
            useDefaultPreset={item.useDefaultPreset ?? true}
            type="isOldData"
          />
        );

      case 'select':
        return (
          <Select
            key={item.names[0]}
            name={item.names[0]}
            classNameColumn={item.classNameColumn}
            classNameSelect={item.classNameElement}
            inputRef={register}
            onRemoveTag={handleRemoveTag}
            options={item.options}
            onChange={submitSearch}
            emptySelectionLabel={item.placeholder}
            disableAllValue={item.disableAllValueSelect}
          />
        );

      case 'input':
        return (
          <Input
            key={item.names[0]}
            name={item.names[0]}
            classNameColumn={item.classNameColumn}
            classNameInput={item.classNameElement}
            inputRef={register({ setValueAs: (v) => (item.customValue ? item.customValue(v) : v) })}
            onRemoveTag={handleRemoveTag}
            placeholder={item.placeholder}
            type={item.inputType}
            onEnter={() => handleSubmit(handleFormSubmission)()}
          />
        );

      case 'multipleSelect':
        return (
          <MultipleSelect
            key={item.names[0]}
            name={item.names[0]}
            classNameColumn={item.classNameColumn}
            inputRef={register}
            options={item.options}
            onChange={submitSearch}
            emptySelectionLabel={item.placeholder}
            disableAllValue={item.disableAllValueSelect}
            setValue={setValue}
            defaultValue={getQueryParamValue(item.names[0]) as string}
          />
        );

      case 'multipleSelectWithDataSourceAndGetValueString':
        return (
          <MultipleSelectWithDataSource
            key={item.names[0]}
            name={item.names[0]}
            classNameColumn={item.classNameColumn}
            control={control}
            options={item.options}
            onChange={submitSearch}
            placeholder={item.placeholder}
            disableAllValue={item.disableAllValueSelect}
            setValue={setValue}
            defaultValue={getQueryParamValue(item.names[0]) as string}
            isSmall
          />
        );

      case 'searchSelect':
        return (
          <SearchSelect
            key={item.names[0]}
            name={item.names[0]}
            classNameColumn={item.classNameColumn}
            inputRef={register}
            options={item.options}
            onChange={submitSearch}
            emptySelectionLabel={item.placeholder}
            disableAllValue={item.disableAllValueSelect}
            setValue={setValue}
            defaultValue={getQueryParamValue(item.names[0]) as string}
            translation={item?.translation}
          />
        );

      case 'searchSelectInputChange':
        return (
          <SelectInputChange
            key={item.names[0]}
            name={item.names[0]}
            classNameColumn={item.classNameColumn}
            inputRef={register}
            options={item.options}
            onChange={submitSearch}
            emptySelectionLabel={item.placeholder}
            disableAllValue={item.disableAllValueSelect}
            setValue={setValue}
            defaultValue={getQueryParamValue(item.names[0]) as string}
            translation={item?.translation}
          />
        );

      case 'inputRange':
        return (
          <InputRange
            key={item.names.join()}
            name={item.names}
            classNameColumn={item.classNameColumn}
            control={control}
            onChange={submitSearch}
            placeholder={item.placeholder}
            setValue={setValue}
            defaultValue={[getQueryParamValue(item.names[0]) as string, getQueryParamValue(item.names[1]) as string]}
            translation={item?.translation}
          />
        );

      default:
    }
  };

  useEffect(() => {
    toolConf.forEach((item) => {
      item.names.forEach((name) => {
        setValue(name, queryParam[name] ?? undefined);
      });
    });

    // setValue('object', queryParam);
  }, [queryParam, toolConf, setValue]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmission)}>
      <div className="columns is-multiline MS_Search_2-container is-variable is-1">
        {toolConf.map((item: ToolType, index: number) => renderSearchElement(item, index))}

        {children}

        <div className="column is-narrow">
          <div className="columns is-variable is-1">
            {customActions}
            {!disableSearchButton && (
              <div className="column">
                <button className="button is-small is-primary" type="submit">
                  <FormattedMessage id="Search" />
                </button>
              </div>
            )}
            {!disableResetButton && (
              <div className="column">
                <button
                  className="button is-small is-warning"
                  onClick={(e) => {
                    e.preventDefault();
                    refreshSearchCriteria();
                  }}
                >
                  <FormattedMessage id="Reset" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default MsToolPanel;
