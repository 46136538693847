import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  onRemoveTag?: (names: string[]) => void;
  name?: string;
  inputRef?: React.ClassAttributes<HTMLInputElement>['ref'];
  defaultValue?: string;
  placeholder?: string;
  classNameColumn?: string;
  classNameInput?: string;
  type?: string;
  onEnter?: (value: string) => void;
};

const Input = (props: Props) => {
  const { onRemoveTag, name, inputRef, defaultValue, placeholder, classNameColumn, classNameInput, type, onEnter } =
    props;

  const { formatMessage } = useIntl();

  const reset = (e: MouseEvent) => {
    e.preventDefault();
    if (name) {
      onRemoveTag?.([name]);
    }
  };

  return (
    <div className={`column ${classNameColumn ?? ''}`}>
      <div className="columns">
        <div className="column is-narrow">
          <div className="control has-icons-right">
            <input
              name={name}
              ref={inputRef}
              className={`input is-small ${classNameInput ?? ''}`}
              type={type ?? 'text'}
              placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
              defaultValue={defaultValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onEnter?.((e?.target as Record<string, any>)?.value);
                }
              }}
            />
            <span className="icon is-small is-right">
              <button className="delete is-small" onClick={reset} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Input;
