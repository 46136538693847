import { stringifyUrl } from 'query-string';
import { MS_API } from '../../ms-core/utils/api';

export const getUIConfigById = (language: string, section: string, platform?: string) => {
  return new Promise<Record<string, any>>((resolve, reject) => {
    MS_API.get<Record<string, any>>(
      stringifyUrl(
        { url: `/common/ui-config/${platform ? `d/${platform}/` : ''}${language}?section=${section}` },
        { skipEmptyString: true, skipNull: true },
      ),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const updateUIConfigById = (data: Record<string, any>, language: string, section: string, platform?: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(
      stringifyUrl({ url: `/common/ui-config/${platform ? `d/${platform}/` : ''}${language}?section=${section}` }),
      data,
    )
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const refreshUIConfigById = (language: string, section: string, platform?: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.put<never>(
      stringifyUrl({
        url: `/common/ui-config/${platform ? `d/${platform}/` : ''}${language}?section=${section}/refresh`,
      }),
    )
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const getLangUIConfig = () => {
  return new Promise<Record<string, any>[]>((resolve, reject) => {
    MS_API.get<Record<string, any>[]>(
      stringifyUrl({ url: `/common/ui-config/languages` }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const getSectionUIConfig = (language: string, platform?: string) => {
  return new Promise<string[]>((resolve, reject) => {
    MS_API.get<string[]>(
      stringifyUrl(
        { url: `/common/ui-config/${platform ? `d/${platform}/` : ''}${language}/sections` },
        { skipEmptyString: true, skipNull: true },
      ),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const createSectionUIConfigById = (
  data: Record<string, any>,
  language: string,
  section: string,
  platform?: string,
) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(
      stringifyUrl({ url: `/common/ui-config/${platform ? `d/${platform}/` : ''}${language}?section=${section}` }),
      data,
    )
      .then(() => resolve())
      .catch(() => reject());
  });
};
