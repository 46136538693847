/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment, { Moment } from 'moment';
import React from 'react';
// @ts-ignore
import Picker from 'rc-calendar/lib/RangeCalendar';
import GGTimePicker from '../gg-time-picker';
import './gg-date-range-picker.scss';
import { useIntl } from 'react-intl';

type Props = {
  value: [Moment?, Moment?];
  defaultValue?: [Moment, Moment];
  buttonClass?: string;
  onReset?: () => void;
  onChange?: (dates: [Moment?, Moment?]) => void;
  onOk?: (dates: [Moment, Moment]) => void;
  className?: string;
  placeholder?: string;
};

const GGDateRangePicker = (props: Props) => {
  const { value, defaultValue, buttonClass, onReset, onChange, onOk, className, placeholder } = props;

  const { formatMessage } = useIntl();

  const handleOnReset = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (value && onReset) onReset();
  };

  const renderFooter = () => (
    <a className="rc-calendar-time-picker-btn" onClick={handleOnReset}>
      Reset
    </a>
  );

  return (
    <div className={`dropdown is-hoverable Gg_Date_Range_Picker-container ${className || ''}`}>
      <div className="dropdown-trigger">
        <button className={`button is-small ${buttonClass ?? ''}`} aria-haspopup="true" aria-controls="dropdown-menu2">
          {value[0] || value[1] ? (
            <>
              <span className="">{value[0] ? value[0].format('DD/MM/YYYY HH:mm:ss') : '...'}</span>
              <span className="icon is-small">
                <i className="mdi mdi-arrow-right" aria-hidden="true" />
              </span>
              <span className="">{value[1] ? value[1].format('DD/MM/YYYY HH:mm:ss') : '...'}</span>
            </>
          ) : (
            <span className="has-text-gray">
              --
              {(placeholder && formatMessage({ id: placeholder })) ||
                `${formatMessage({ id: 'Date range selection' })}`}
              --
            </span>
          )}
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div className="dropdown-item">
          <Picker
            onOk={onOk}
            onChange={onChange}
            timePicker={GGTimePicker}
            defaultSelectedValue={defaultValue ?? [moment().startOf('d'), moment().endOf('d')]}
            format="YYYY-MM-DD HH:mm:ss"
            renderFooter={onReset ? renderFooter : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default GGDateRangePicker;
