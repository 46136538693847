import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from '../header/header.layout';
import { Switch, Route } from 'react-router-dom';
import appRoutes from '../../_routes';
import { useAuth } from '../../ms-core/utils/auth';
import { useMsRouter, useRouter } from '../../ms-core/utils/routers';
import { IRootLayoutProps } from './root.layout.type';
import { Aside } from '../aside';
import { Footer } from '../footer';
// import Tab from '../../ms-core/blocks/tab';
// import { Breadcrumb } from '../breadcrumb';

const TABS = appRoutes.map((route) => ({
  label: route.name,
  id: route.path,
  closeable: true,
  // valueOfTranslation: route?.valueOfTranslation,
}));

export const Layout: React.FC<IRootLayoutProps> = () => {
  const [openTabs, setOpenTabs] = useState([TABS[0]] as typeof TABS);

  const { accessToken } = useAuth();

  const msRouter = useMsRouter();

  const { pathname, push } = useRouter();

  const loading = useMemo(() => {
    return (
      <div className="animated fadeIn pt-1 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
      </div>
    );
  }, []);

  const onCloseTab = useCallback(
    (index: number) => {
      if (openTabs.length === 1) return;
      setOpenTabs([...openTabs.filter((tab, i) => i !== index)]);
      if (openTabs[index].id === pathname) push(openTabs[index - 1].id);
    },
    [openTabs, pathname, push],
  );

  useEffect(() => {
    if (!accessToken) {
      push('/auth/signin');
    }
  }, [accessToken, push]);

  useEffect(() => {
    const tab = TABS.find((tab) => tab.id === pathname);

    if (!tab) return;

    if (!openTabs.find((tab) => tab.id === pathname)) {
      openTabs.push(tab);
    }
  }, [openTabs, pathname]);

  return (
    <div id="app">
      <Header />
      <Aside />
      <div id="main-content">
        <section className="section">
          {/* <Tab tabs={openTabs} fullUrl={pathname} pageUrl="" className="is-small is-no-scroll" onClose={onCloseTab} />
          <Breadcrumb /> */}
          <Suspense fallback={loading}>
            <Switch>
              {appRoutes.map((route, idx) => {
                const { path, exact, component: _component, name: _name, ...rest } = route;
                return route.component ? (
                  <Route
                    key={idx}
                    strict
                    path={path}
                    exact={exact}
                    render={(props) => <route.component {...(props as any)} {...rest} msRouter={msRouter} />}
                  />
                ) : null;
              })}
            </Switch>
          </Suspense>
        </section>
      </div>

      <Footer />
    </div>
  );
};
