import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '../../../../../components/form-detail/FormControl';
import { useModal } from '../../../../../ms-core/hook/use-modal';
import { MsType } from '../../../../../ms-core/models/ms-type';
import { showToast } from '../../../../../ms-core/utils/toast';
import { createSectionAdminUIConfigById } from '../../../../../services/common/admin-builders';

type Props = {
  lang: string;
  refreshData: () => void;
};

export const AddSection: React.FC<Props> = ({ refreshData, lang }) => {
  const { showModal, onHide } = useModal();

  function onClick() {
    showModal(<AddSectionForm lang={lang} onHide={onHide} refreshData={refreshData} />);
  }

  return (
    <button className="button is-primary is-small" onClick={onClick}>
      <span className="icon">
        <i className="mdi mdi-plus" />
      </span>
      <span>
        <FormattedMessage id="Create section" />
      </span>
    </button>
  );
};

type AddSectionFormProps = {
  refreshData: () => void;
  onHide: () => void;
  lang: string;
};

export const AddSectionForm: React.FC<AddSectionFormProps> = ({ refreshData, onHide, lang }) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const listConf: MsType[] = [
    {
      name: 'Section',
      field: 'section',
      type: 'text',
      required: true,
    },
    {
      name: 'Content',
      field: 'content',
      type: 'json',
    },
  ];

  const onSubmit = (values: any) => {
    setLoading(true);
    createSectionAdminUIConfigById(values.content ?? {}, lang, values.section)
      .then(() => {
        showToast.success('Create resource success!');
        onHide();
        refreshData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FormControl
      isLoading={isLoading}
      title="Create section"
      conf={listConf}
      onSubmitData={onSubmit}
      closeModal={onHide}
    />
  );
};
