import { stringifyUrl } from 'query-string';
import { API_ENDPOINT } from '../../constants/api-endpoint';
import { MS_API } from '../../ms-core/utils/api';

export const forceLogoutAdmin = (id?: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(stringifyUrl({ url: API_ENDPOINT.FORCE_LOG_OUT_ADMIN }), { adminId: id })
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const resetPasswordAdmin = (id?: string) => {
  return new Promise<string>((resolve, reject) => {
    MS_API.post<string>(stringifyUrl({ url: `${API_ENDPOINT.ADMINS}/${id}/resetPassword` }))
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

type changPasswordDTO = {
  newPassword: string;
  password: string;
  id: string;
};

export const changePasswordAdmin = (data: changPasswordDTO) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(stringifyUrl({ url: `${API_ENDPOINT.ADMINS}/${data.id}/changePassword` }), {
      newPassword: data.newPassword,
      password: data.password,
    })
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const changePasswordAdminOnly = (data: changPasswordDTO) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(stringifyUrl({ url: API_ENDPOINT.CHANGE_PASSWORD_ADMIN }), {
      newPassword: data.newPassword,
      password: data.password,
    })
      .then(() => resolve())
      .catch(() => reject());
  });
};

type createAdminAdminDTO = {
  roles: string[];
  password: string;
  username: string;
};

export const createAdminAdmin = (data: createAdminAdminDTO) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(stringifyUrl({ url: API_ENDPOINT.ADMINS }), {
      roles: data.roles,
      password: data.password,
      username: data.username,
    })
      .then(() => resolve())
      .catch(() => reject());
  });
};
