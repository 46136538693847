/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { DatePicker, HiddenInput, Input, Textarea } from '../../../ms-core/blocks/controls';
import { DateFormat, DatetimeFormat } from '../../../ms-core/const/date-time';
import { useConfirm } from '../../../ms-core/hook/use-confirm';
import { MsType } from '../../../ms-core/models/ms-type';
import { DataSource } from '../../../ms-core/types/status-source-type';
import { formatCurrencies } from '../../../utils/card-money';
import { JSONEditorControl } from '../../ace-editor/ace-editor.component';
import CustomCreatableSelect from '../../custom-creatable-select';
import CustomSelect from '../../custom-selection';
import { ModalUploadFileForm } from '../../modal-upload-image';
import MultiSelect from '../../multiple-selection';
import { MultipleSelectWithDataSource } from '../../multiple-selection/multiple-select-with-data-source';
import { ModalUploadFileServicer } from '../../modal-upload-image/modal-upload-file-service';
import { ScrollBarControl } from '../../scroll-bar-control';
import { EditorControl } from '../../editor';
import { HTTMLEditorControl } from '../../html-editor-control';
import PhoneNumber from '../../custom-selection/custom-select-with-phone';
import CheckBoxControl from '../../check-box';
import { ModalUploadFileServicerWithSuggestedPhotosForm } from '../../modal-upload-image/select-method-upload-photos';
import { TextareaControl } from '../../texarea-control/texarea-control';
import { adminUploadImage } from '../../../services/account/common';
import { convertToSlug } from '../../../utils/helper';

export type FieldConfirm = {
  field?: string;
  type?: 'number' | 'money' | 'text';
  customField?: unknown;
};

interface FormControlProp {
  onSubmitData?: (value: Record<string, unknown>) => void;
  fetchData?: (value: string) => void;
  id?: string;
  closeModal?: () => void;
  conf?: MsType[];
  isLoading?: boolean;
  defaultValues?: Record<string, unknown>;
  title?: string;
  rightHeader?: string;
  confirmField?: FieldConfirm;
  renderMore?: React.ReactNode;
  isImageHasAsset?: boolean;
  disableCloseButton?: boolean;
  renderLeftBottom?: (e: Record<string, any>) => React.ReactNode;
  renderRightHeader?: (e: Record<string, any>) => React.ReactNode;
  maxHeight?: string;
  icon?: string;
}

const FormControl = (props: FormControlProp) => {
  const {
    defaultValues,
    onSubmitData,
    fetchData,
    id,
    closeModal,
    conf,
    title,
    rightHeader,
    confirmField,
    isLoading,
    renderMore,
    // isImageHasAsset = false,
    disableCloseButton = false,
    renderLeftBottom,
    maxHeight = '95vh',
    renderRightHeader,
    icon,
  } = props;
  const { formatMessage } = useIntl();
  const { showConfirm } = useConfirm();
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch,
    setError,
  } = useForm();
  const [loading, setLoading] = useState<boolean>(isLoading ?? false);

  async function submitDetail() {
    setLoading(true);
    const data = { ...getValues() };
    const fieldImage: MsType<Record<string, unknown>>[] = [];

    conf?.forEach((item) => {
      const { type, field, required } = item || {};

      if (['number', 'money'].includes(type) && field && data?.[field] != null) {
        data[field] = Number(data[field]);
      }

      if (['imageService', 'imageServiceWithSuggested'].includes(type) && field) {
        if (data?.[field] instanceof File) {
          fieldImage.push(item);
        }
      }

      if (['textRemoveSpace', 'textHasSelectRemoveSpace'].includes(type) && field && data?.[field]) {
        data[field] = data[field].replace(/\s/g, '');
      }

      if (['text', 'id'].includes(type) && field && typeof data?.[field] === 'string') {
        // Uncomment below if trimming is needed
        // data[field] = data[field].trim();
      }

      if (!required && !data?.[field]) {
        delete data[field];
      }
    });

    // Handle image uploads if any fields are found
    if (fieldImage.length > 0) {
      setLoading(true);

      const promises = fieldImage.map(({ field, folderNameUpload, fileName }) =>
        adminUploadImage(data[field] as File, {
          folderName: folderNameUpload as string,
          fileName: fileName as string,
        }).then((url) => {
          const regex = /\/cbtcdn\/.*/;
          const match = url.match(regex);

          if (match) {
            data[field] = match[0];
          }
        }),
      );

      await Promise.all(promises)
        .then(() => {
          onSubmitData?.(data);
        })
        .finally(() => setLoading(false));
    } else {
      onSubmitData?.(data);
      setLoading(false);
    }
  }

  const renderConfirmField = (value: any) => {
    switch (confirmField?.type) {
      case 'number':
      case 'money':
        return confirmField?.field ? `${formatCurrencies.BCOIN(Number(value?.[confirmField.field] || ''))}` : 'confirm';

      case 'text':
        return confirmField?.field ? value?.[confirmField.field] : 'confirm';

      default:
        return confirmField?.customField ?? 'confirm';
    }
  };

  function onSubmit(value: any) {
    if (confirmField) {
      title && showConfirm(title, renderConfirmField(value), { onConfirm: submitDetail });
    } else {
      submitDetail();
    }
  }

  useEffect(() => {
    id && fetchData?.(id);
  }, [fetchData, id]);

  useEffect(() => {
    conf &&
      conf.map((item: MsType) => {
        setValue(item?.field, defaultValues?.[item?.field]);
      });
  }, [conf, defaultValues, setValue]);

  function renderSearchElement(item: MsType): React.ReactNode {
    switch (item.type) {
      case 'select':
      case 'status':
      case 'tag':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              {item.dataSource ? (
                <CustomSelect
                  name={item.field}
                  options={item.dataSource}
                  errors={errors?.[item?.field] && true}
                  disabled={item?.readonly}
                  placeholder={item.name}
                  defaultValue={defaultValues?.[item?.field]}
                  control={control}
                  rules={{
                    required: {
                      value: !!item.required,
                      message: 'This field is required!',
                    },
                  }}
                />
              ) : (
                <Input
                  type={item.type}
                  readOnly={false}
                  className={`input${errors?.[item?.field] ? ' is-danger' : ''}`}
                  name={item.field}
                  control={control}
                  rules={{
                    required: {
                      value: !!item.required,
                      message: 'This field is required!',
                    },
                  }}
                  placeholder={item.name}
                  disabled={item?.readonly}
                />
              )}
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'phone':
        return <PhoneNumber item={item} errors={errors} register={register} setValue={setValue} setError={setError} />;

      case 'number':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <Input
                type={item.type}
                readOnly={false}
                className={`input${errors?.[item?.field] ? ' is-danger' : ''}`}
                name={item.field}
                control={control}
                step={item.numberStep ? item.numberStep : 1}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
                placeholder={item.name}
                disabled={item?.readonly}
                min={item?.min}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'money':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <Controller
                render={({ onChange, value }) => (
                  <NumberFormat
                    className={`input ${errors?.[item?.field] ? ' is-danger' : ''}`}
                    thousandSeparator
                    onValueChange={(v) => onChange(v.value)}
                    value={value}
                    disabled={item?.readonly}
                    placeholder={item.name ? formatMessage({ id: item.name }) : ''}
                  />
                )}
                name={item.field}
                control={control}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                  min: {
                    value: item?.min as number,
                    message: `${item?.name} must be greater than or equal to ${item?.min}`,
                  },
                }}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'textRemoveSpace':
      case 'email':
      case 'text':
      case 'player':
      case 'id':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    {item.name && <FormattedMessage id={item.name} />}
                    &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
                  </div>
                </div>
                {!!item?.fieldWatchGenSlug && (
                  <div className="level-right">
                    <div className="level-item">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          const title = getValues()?.[item?.fieldWatchGenSlug?.title ?? ''];
                          const categoryId =
                            getValues()?.[item?.fieldWatchGenSlug?.categoryId ?? ''] ??
                            item?.fieldWatchGenSlug?.categoryId;
                          const slug = convertToSlug(title, categoryId);

                          setValue(item?.field, slug);
                        }}
                        className="is-small button is-ghost"
                      >
                        <i className="mdi mdi-autorenew" /> <span>Create links</span>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </label>

            <div className="control">
              <Input
                type={item.type}
                readOnly={false}
                className={`input${errors?.[item?.field] ? ' is-danger' : ''}`}
                name={item.field}
                control={control}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                  pattern: {
                    value: item?.pattern?.value as RegExp,
                    message: item?.pattern?.message as string,
                  },
                }}
                placeholder={item.help ? item.help : item.name}
                disabled={item?.readonly}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
            {item?.help && <p className="help has-text-success">{item?.help} </p>}
          </div>
        );

      case 'multipleSelect':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <MultiSelect
                key={item.field}
                name={item.field}
                options={(defaultValues?.[item?.field] as string[])?.join(',')}
                control={control}
                errors={!!errors?.[item?.field]}
                placeholder={item.name}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
                defaultValue={(defaultValues?.[item?.field] as string[])?.join(',')}
                isMulti
                disabled={item?.readonly}
                getArray
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'multipleSelectWithDataSourceAndGetValueArray':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <MultipleSelectWithDataSource
                name={item.field}
                options={item.dataSource}
                errors={errors?.[item?.field] && true}
                disabled={item?.readonly}
                placeholder={item.name}
                defaultValue={defaultValues?.[item?.field] as any}
                control={control}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
                isGetArray
                defaultValueFixed={item?.defaultValueFixed}
                notCheckResetValue
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'multipleSelectWithDataSourceAndGetValueString':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <MultipleSelectWithDataSource
                name={item.field}
                options={item.dataSource}
                errors={errors?.[item?.field] && true}
                disabled={item?.readonly}
                placeholder={item.name}
                defaultValue={defaultValues?.[item?.field] as any}
                control={control}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
                defaultValueFixed={item?.defaultValueFixed}
                notCheckResetValue
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'multipleSelectWithDataSourceAndGetValueArrayNotCreate':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <MultipleSelectWithDataSource
                name={item.field}
                options={item.dataSource}
                errors={errors?.[item?.field] && true}
                disabled={item?.readonly}
                placeholder={item.name}
                defaultValue={defaultValues?.[item?.field] as any}
                control={control}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
                isGetArray
                isNotCreate
                notTranslation={!item.translation}
                defaultValueFixed={item?.defaultValueFixed}
                notCheckResetValue
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'textHasSelect':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <CustomCreatableSelect
                style={{ minWidth: '338px' }}
                control={control}
                name={item?.field}
                errors={!!errors?.[item?.field]}
                options={item?.dataSource as DataSource}
                placeholder="Instruction"
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required!',
                  },
                }}
                errorsMessage={errors?.[item?.field]?.message}
                defaultValue={defaultValues?.[item?.field] as any}
                textHelp="This message will show for the user"
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'datetime':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <DatePicker
                // style={{ position: 'fixed' }}
                disabled={item.readonly}
                extInputClass={`${item.readonly ? 'is-static has-text-left' : ''} ${
                  errors?.[item?.field] ? ' is-danger' : ''
                }`}
                name={item.field}
                control={control}
                format={item.type === 'datetime' ? DatetimeFormat : DateFormat}
                time
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'image':
        return (
          <>
            <div className="field ">
              <label className="label">
                {item.name && <FormattedMessage id={item.name} />}
                &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
              </label>
              <ModalUploadFileForm
                folder={item.folderNameUpload}
                onChange={(e) => {
                  const linkPattern = new RegExp(`\\/${item.folderNameUpload}\\/\\d+`, 'i');
                  const pathMatch = e.match(linkPattern);
                  setValue(item.field, `/assets/public${pathMatch?.[0]}`);
                }}
                hiddenCopy
                isHasCard={false}
                defaultValue={defaultValues?.[item?.field] as string}
              />
            </div>
            <div className="field">
              <div className="control">
                <Input
                  defaultValue={watch(item.field)}
                  type="input"
                  readOnly={false}
                  className={`input${errors?.[item?.field] ? ' is-danger' : ''}`}
                  name={item.field}
                  control={control}
                  rules={{
                    required: {
                      value: !!item.required,
                      message: 'This field is required!',
                    },
                  }}
                  placeholder={item.name}
                  // disabled
                />
              </div>
              {errors?.[item?.field] && item.required && (
                <p className="help has-text-danger">
                  {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
                </p>
              )}
            </div>
          </>
        );

      case 'imageService':
        return (
          <>
            <div className="field ">
              <label className="label">
                {item.name && <FormattedMessage id={item.name} />}
                &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
              </label>
              <div className="control">
                <ModalUploadFileServicer
                  onChange={(e) => {
                    setValue(item.field, e);
                  }}
                  hiddenCopy
                  isHasCard={false}
                  defaultValue={watch(item.field)}
                />
              </div>
              {errors?.[item?.field] && item.required && (
                <p className="help has-text-danger">
                  {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
                </p>
              )}
            </div>
            <Input
              defaultValue={watch(item.field)}
              type={item.inputSupport ? 'input' : 'hidden'}
              readOnly={false}
              className={`input${errors?.[item?.field] ? ' is-danger' : ''}`}
              name={item.field}
              control={control}
              rules={{
                required: {
                  value: !!item.required,
                  message: 'This field is required!',
                },
              }}
              placeholder={item.name}
              disabled={!item.inputSupport}
            />
          </>
        );

      case 'imageServiceWithSuggested':
        return (
          <>
            <div className="field ">
              <label className="label">
                {item.name && <FormattedMessage id={item.name} />}
                &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
              </label>
              <div className="control">
                <ModalUploadFileServicerWithSuggestedPhotosForm
                  onChange={(e) => {
                    setValue(item.field, e);
                  }}
                  hiddenCopy
                  isHasCard={false}
                  defaultValue={watch(item.field)}
                />
              </div>
              {errors?.[item?.field] && item.required && (
                <p className="help has-text-danger">
                  {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
                </p>
              )}
            </div>
            <Input
              defaultValue={watch(item.field)}
              type={item.inputSupport ? 'input' : 'hidden'}
              readOnly={false}
              className={`mt-2 mb-2 input${errors?.[item?.field] ? ' is-danger' : ''}`}
              name={item.field}
              control={control}
              rules={{
                required: {
                  value: !!item.required,
                  message: 'This field is required!',
                },
              }}
              placeholder={item.name}
              disabled={!item.inputSupport}
            />
          </>
        );

      case 'json':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <JSONEditorControl
                defaultValue={defaultValues?.[item?.field] as Record<string, unknown>}
                name={item.field}
                control={control}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'editor':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <EditorControl
                defaultValue={defaultValues?.[item?.field] as string}
                name={item.field}
                control={control}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'htmlEditor':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <HTTMLEditorControl
                defaultValue={watch(item.field)}
                name={item.field}
                control={control}
                width="100%"
                height="60vh"
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
            <hr />
            {/* <span style={{ width: '500px' }} dangerouslySetInnerHTML={{ __html: watch(item.field) }} /> */}
          </div>
        );

      case 'textArea':
        return (
          <div className="field" key={item.field}>
            <label className="label">
              {item.name && <FormattedMessage id={item.name} />}
              &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
            </label>
            <div className="control">
              <Textarea
                className={`textarea${errors?.[item?.field] ? ' is-danger' : ''}`}
                name={item.field}
                control={control}
                rows={10}
                rules={{
                  required: {
                    value: !!item.required,
                    message: 'This field is required!',
                  },
                }}
                placeholder={item.name}
                disabled={item?.readonly}
              />
            </div>
            {errors?.[item?.field] && item.required && (
              <p className="help has-text-danger">
                {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
              </p>
            )}
          </div>
        );

      case 'textAreaAutoHeight':
        return (
          <>
            <div className="field ">
              <label className="label">
                {item.name && <FormattedMessage id={item.name} />}
                &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
              </label>
              <div className="control">
                <TextareaControl
                  onChange={(e) => {
                    setValue(item.field, e);
                  }}
                  defaultValue={watch(item.field)}
                />
              </div>
              {errors?.[item?.field] && item.required && (
                <p className="help has-text-danger">
                  {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
                </p>
              )}
            </div>
            <Input
              defaultValue={watch(item.field)}
              type="hidden"
              readOnly={false}
              className={`input${errors?.[item?.field] ? ' is-danger' : ''}`}
              name={item.field}
              control={control}
              rules={{
                required: {
                  value: !!item.required,
                  message: 'This field is required!',
                },
              }}
              placeholder={item.name}
              disabled
            />
          </>
        );

      case 'checkbox':
        return (
          <div className="field" key={item.field}>
            <CheckBoxControl defaultValue={watch(item.field)} control={control} name={item.field} title={item.name} />
          </div>
        );

      case 'hidden':
        return (
          <div className="field" key={item.field}>
            <HiddenInput name={item.field} control={control} />
          </div>
        );

      default:
    }
  }

  useEffect(() => {
    if (isLoading !== undefined) setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className="form-control">
      <ScrollBarControl id="form-control" style={{ maxHeight }}>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title p-3">
              {icon && (
                <span className="icon">
                  <i className={`mdi mdi-${icon}`} />
                </span>
              )}
              {title && <FormattedMessage id={title} />} {rightHeader}
            </p>
            {renderRightHeader ? renderRightHeader({}) : null}
          </header>
          <div className="card-content p-0">
            <div className="column is-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  {renderMore && (
                    <>
                      {renderMore}
                      <hr className="w100" />
                    </>
                  )}
                </div>
                {conf && conf?.map((item: MsType) => renderSearchElement(item))}
                <div className="field is-grouped is-grouped-centered">
                  {renderLeftBottom && (
                    <>
                      {renderLeftBottom({
                        getValues,
                        handleSubmit,
                        control,
                        setValue,
                        register,
                        watch,
                        setError,
                      })}
                    </>
                  )}
                  <div className="control">
                    <button
                      disabled={loading}
                      type="submit"
                      className={`button is-primary ${loading ? ' is-loading' : ''}`}
                    >
                      <FormattedMessage id="Submit" />
                      &nbsp;
                    </button>
                  </div>
                  {!disableCloseButton && (
                    <div className="control">
                      <button className="button is-warning" disabled={loading} onClick={() => closeModal?.()}>
                        <FormattedMessage id="Close" />
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </ScrollBarControl>
    </div>
  );
};

export default FormControl;
