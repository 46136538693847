/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { ccyRate, formatCurrencies } from '../../utils/card-money';

const ccyMask = createNumberMask({
  prefix: '',
  suffix: '',
  thousandsSeparatorSymbol: ',',
  integerLimit: 10,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
});

interface Props {
  readonly?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string;
  hideAddOn?: boolean;
  currency?: string;
  className?: string;
  defaultValue?: number | string;
  onValueChange?: any;
}

export default function InputCcy(props: Props) {
  const handleOnInputChange = (e: any) => {
    const amount = e.target.value.replace(/,/g, '');
    const moneyConvert = Number(amount) * ccyRate[props.currency || 'SGD'];

    props.onValueChange(moneyConvert);
  };

  if (props.readonly) {
    return (
      <span className={`${props.className} font-size-bold`}>{formatCurrencies.BCOIN(Number(props.defaultValue))}</span>
    );
  }

  return (
    <div className="field has-addons is-marginless">
      <p className="control ">
        <MaskedInput
          value={Number(props.defaultValue || 0) / ccyRate[props.currency || 'SGD']}
          className={`input ${props.className}`}
          mask={ccyMask}
          onChange={handleOnInputChange}
        />
      </p>
      {!props.hideAddOn && (
        <p className="control">
          <span className={`button ${props.className}`}>{props.currency}</span>
        </p>
      )}
    </div>
  );
}
