import { ValueOfTranslation } from './ms-core/types/status-source-type';

export interface INavigation {
  items: IMenuSection[];
}

export interface IMenuItem {
  name: string;
  valueOfTranslation?: ValueOfTranslation;
  url: string;
  icon: string;
  topUp?: string;
}

export interface IMenuGroup {
  name: string;
  valueOfTranslation?: ValueOfTranslation;
  url: string;
  icon: string;
  subMenus: IMenuItem[];
  topUp?: string;
}

export interface IMenuSection {
  name: string;
  valueOfTranslation?: ValueOfTranslation;
  title: boolean;
  child: (IMenuItem | IMenuGroup)[];
}

const menus: IMenuSection[] = [];

const report: IMenuSection = {
  name: 'Report',
  title: true,
  child: [
    {
      name: 'Overview',
      url: '/dashboard',
      icon: 'mdi-view-dashboard',
    },
    {
      name: 'Analysis',
      url: '/analysis',
      icon: 'mdi-chart-line',
    },
    {
      name: 'Traffic Insights',
      url: '/traffic-insights',
      icon: 'mdi-traffic-light',
    },
    {
      name: 'Attack Insights',
      url: '/attack-insights',
      icon: 'mdi-shield-alert',
    },
  ],
};

menus.push(report);

const configs: IMenuSection = {
  name: 'Configuration',
  title: true,
  child: [
    {
      name: 'Application',
      url: '/application',
      icon: 'mdi-application',
    },
    {
      name: 'Allow Rule',
      url: '/allow-rule',
      icon: 'mdi-shield-check',
    },
    {
      name: 'Block Rule',
      url: '/block-rule',
      icon: 'mdi-shield-off',
    },
  ],
};

menus.push(configs);

const accountManagement: IMenuSection = {
  name: 'Configuration',
  title: true,
  child: [
    {
      name: 'Billing',
      url: '/billing',
      icon: 'mdi-receipt',
    },
  ],
};

menus.push(accountManagement);

const navigation = {
  items: menus,
};

export default navigation;
