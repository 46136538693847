/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import CustomSelect from '../../../../../components/custom-selection';
import { EditorControl } from '../../../../../components/editor';
import { HTTMLEditorControl } from '../../../../../components/html-editor-control';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import { ModalUploadFileServicerWithSuggestedPhotosForm } from '../../../../../components/modal-upload-image/select-method-upload-photos';
import SwitchButton from '../../../../../components/switch-button';
import { EditorType, EditorTypeSource } from '../../../../../constants/types/ui-config-type';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { Input } from '../../../../../ms-core/blocks/controls';
import MsNoData from '../../../../../ms-core/blocks/ms-nodata';
import { useConfirm } from '../../../../../ms-core/hook/use-confirm';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { showToast } from '../../../../../ms-core/utils/toast';
import { adminUploadImage } from '../../../../../services/account/common';
import { getLangUIConfig, getUIConfigById, updateUIConfigById } from '../../../../../services/common/website-builders';
import { Select } from '../../components/select';

const WelcomePopupWui = () => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);
  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();
  const [languageTypeList, setLanguageTypeList] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, handleSubmit, reset, watch, errors, register, setValue } = useForm({ shouldUnregister: false });
  const { showConfirm } = useConfirm();
  const [response, setResponse] = useState<Record<string, any>>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content',
  });

  const changeDataFollowLang = async (lg: string) => {
    setLang(lg as LANGUAGE);
  };

  const fetchDataLang = () => {
    setLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        setLanguageTypeList(resLang);
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setLoading(false));
  };

  const fetchData = async (lg: string) => {
    setIsLoading(true);
    setTimeout(() => {
      getUIConfigById(lg, 'app-shell-config')
        .then((res) => {
          res.welcomeModal.content.forEach((e: { typeEditor: EditorType }) => {
            e.typeEditor = e.typeEditor ?? EditorType.WORD_EDITOR;
          });
          setResponse(res);
          reset(res.welcomeModal);
        })
        .catch(() =>
          reset({
            content: [],
            image: '',
            isEnable: false,
            isLightBox: false,
            title: '',
          }),
        )
        .finally(() => setIsLoading(false));
    }, 100);
  };

  const onSubmit = (values: Record<string, any>) => {
    if (values?.image instanceof File) {
      adminUploadImage(values.image, {
        folderName: 'welcome-popup',
      }).then((res) => {
        const linkPattern = /\/welcome-popup\/\d+/i;
        const pathMatch = res.match(linkPattern);
        values.image = `/assets/public/${pathMatch?.[0]}`;

        updateUIConfigById({ ...response, ...{ welcomeModal: values } }, lang, 'app-shell-config').then(() => {
          showToast.success('Update Resource Success');
          fetchData(lang);
        });
      });
    } else {
      updateUIConfigById({ ...response, ...{ welcomeModal: values } }, lang, 'app-shell-config').then(() => {
        showToast.success('Update Resource Success');
        fetchData(lang);
      });
    }
  };

  const onConfirmSubmit = (values: Record<string, any>) => {
    showConfirm('You have changes made. Would you like to save current progress?', 'Accept', {
      onConfirm: () => onSubmit(values),
    });
  };

  useEffect(() => {
    fetchDataLang();
  }, []);

  useEffect(() => {
    if (!lang) return;

    fetchData(lang);
  }, [lang]);

  return (
    <div className="app-shell-config section">
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-content-save-cog" />
                  </span>
                  <span>
                    <FormattedMessage id="Popup Website" />
                  </span>
                </p>
                <a className="card-header-icon">
                  <button className="is-small button is-ghost mr-0">
                    {!!languageTypeSource && (
                      <Select
                        onChangeData={changeDataFollowLang}
                        defaultValue={lang}
                        name="lang"
                        options={languageTypeSource}
                        disableAllValue
                      />
                    )}
                  </button>

                  <ModalUploadFile folderName="ui-config" isHasAccessPublic />

                  <button
                    onClick={() => {
                      append({
                        title: 'New title',
                        content: '<div>New content</div>',
                        typeEditor: EditorType.WORD_EDITOR,
                      });
                      window.scrollTo(0, 9999);
                    }}
                    type="button"
                    className="button is-primary is-small"
                  >
                    <span className="icon">
                      <i className="mdi mdi-plus default" />
                    </span>
                    <span>
                      <FormattedMessage id="Create new content" />
                    </span>
                  </button>

                  <button onClick={() => fetchData(lang)} type="button" className="button is-small">
                    <span className="icon">
                      <i className="mdi mdi-refresh default" />
                    </span>
                    <span>
                      <FormattedMessage id="Refresh data" />
                    </span>
                  </button>
                </a>
              </header>

              <div className="card-content pt-0">
                <div className="content">
                  <div className="app-shell-config">
                    <form onSubmit={handleSubmit(onConfirmSubmit)}>
                      <div className="field p-5 is-border">
                        <div className="field-label is-normal has-text-left pb-4	">
                          <div className="label">{isLoading ? <Skeleton count={1} /> : 'Genneral'}</div>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <div className="info-description">
                                <div className="field pt-2">
                                  <label className="label">
                                    <div className="level">
                                      <div className="level-left">
                                        <div className="level-item">
                                          {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Title" />}
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="control">
                                    {isLoading ? (
                                      <Skeleton width="100%" count={1} />
                                    ) : (
                                      <Input
                                        defaultValue={watch(`title`)}
                                        className={`input${errors?.title ? ' is-danger' : ''}`}
                                        name="title"
                                        control={control}
                                        rules={{
                                          required: {
                                            value: true,
                                            message: 'This field is required!',
                                          },
                                        }}
                                        placeholder="Title"
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="field pt-2">
                                  <label className="label">
                                    <div className="level">
                                      <div className="level-left">
                                        <div className="level-item">
                                          {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Image" />}
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="control">
                                    {isLoading ? (
                                      <Skeleton width="100%" count={1} />
                                    ) : (
                                      <>
                                        <ModalUploadFileServicerWithSuggestedPhotosForm
                                          onChange={(e) => {
                                            setValue('image', e);
                                          }}
                                          hiddenCopy
                                          isHasCard={false}
                                          defaultValue={watch(`image`)}
                                        />
                                        {/* <ModalUploadFileServicer
                                          onChange={(e) => {
                                            setValue('image', e);
                                          }}
                                          hiddenCopy
                                          isHasCard={false}
                                          defaultValue={watch(`image`)}
                                        /> */}

                                        <Input
                                          defaultValue={watch('image')}
                                          type="input"
                                          readOnly={false}
                                          className={`mt-2 input${errors?.image ? ' is-danger' : ''}`}
                                          name="image"
                                          control={control}
                                          rules={{
                                            required: {
                                              value: false,
                                              message: 'This field is required!',
                                            },
                                          }}
                                          placeholder="Image"
                                        />
                                        {errors?.image && (
                                          <p className="help has-text-danger">
                                            {errors?.image.message && <FormattedMessage id={errors?.image.message} />}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>

                                <div className="field pt-2">
                                  <label className="label">
                                    <div className="level">
                                      <div className="level-left">
                                        <div className="level-item">
                                          {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Is enable" />}
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="control">
                                    {isLoading ? (
                                      <Skeleton width="100%" count={1} />
                                    ) : (
                                      <SwitchButton
                                        register={register}
                                        name="isEnable"
                                        value={watch('isEnable')}
                                        mbNumber={0}
                                        setValue={setValue}
                                        turnOff
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="field pt-2">
                                  <label className="label">
                                    <div className="level">
                                      <div className="level-left">
                                        <div className="level-item">
                                          {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Is light box" />}
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="control">
                                    {isLoading ? (
                                      <Skeleton width="100%" count={1} />
                                    ) : (
                                      <SwitchButton
                                        register={register}
                                        name="isLightBox"
                                        value={watch('isLightBox')}
                                        mbNumber={0}
                                        setValue={setValue}
                                        turnOff
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {!_.isEmpty(fields) && !!fields ? (
                        fields.map((item, index) => {
                          return (
                            <>
                              <div key={item.id} className="field p-5 is-border">
                                <div className="field-label is-normal has-text-left pb-4	">
                                  <div className="label">
                                    {isLoading ? <Skeleton count={1} /> : watch(`content.${index}.title`)}
                                  </div>
                                </div>
                                <div className="field-body">
                                  <div className="field">
                                    <div className="control">
                                      <div className="info-description">
                                        <div className="field pt-2">
                                          <label className="label">
                                            <div className="level">
                                              <div className="level-left">
                                                <div className="level-item">
                                                  {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Name" />}
                                                </div>
                                              </div>
                                              <div className="level-right">
                                                <div className="level-item">
                                                  {isLoading ? (
                                                    <Skeleton count={1} />
                                                  ) : (
                                                    <button
                                                      onClick={() => remove(index)}
                                                      type="button"
                                                      className="button is-danger is-small ml-2"
                                                    >
                                                      <span className="icon">
                                                        <i className="mdi mdi-delete-alert default" />
                                                      </span>
                                                      <span>
                                                        <FormattedMessage id="Remove" />
                                                      </span>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </label>

                                          <div className="control">
                                            {isLoading ? (
                                              <Skeleton width="100%" count={1} />
                                            ) : (
                                              <Input
                                                defaultValue={watch(`content.${index}.title`)}
                                                className={`input${
                                                  errors?.[`content.${index}.title`] ? ' is-danger' : ''
                                                }`}
                                                name={`content.${index}.title`}
                                                control={control}
                                                rules={{
                                                  required: {
                                                    value: true,
                                                    message: 'This field is required!',
                                                  },
                                                }}
                                                placeholder="Title"
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="field pt-2">
                                          <label className="label">
                                            <div className="level">
                                              <div className="level-left">
                                                <div className="level-item">
                                                  {isLoading ? (
                                                    <Skeleton count={1} />
                                                  ) : (
                                                    <FormattedMessage id="Type editor" />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </label>
                                          <div className="control">
                                            {isLoading ? (
                                              <Skeleton width="100%" count={1} />
                                            ) : (
                                              <CustomSelect
                                                name={`content.${index}.typeEditor`}
                                                options={EditorTypeSource}
                                                placeholder="Type"
                                                defaultValue={watch(`content.${index}.typeEditor`)}
                                                control={control}
                                                rules={{
                                                  required: {
                                                    value: true,
                                                    message: 'This field is required!',
                                                  },
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="field pt-2">
                                          <label className="label">
                                            <div className="level">
                                              <div className="level-left">
                                                <div className="level-item">
                                                  {isLoading ? (
                                                    <Skeleton count={1} />
                                                  ) : (
                                                    <FormattedMessage id="Content" />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </label>
                                          <div className="control">
                                            {isLoading ? (
                                              <Skeleton width="100%" height="50vh" count={1} />
                                            ) : (
                                              <>
                                                {watch(`content.${index}.typeEditor`) === EditorType.WORD_EDITOR && (
                                                  <EditorControl
                                                    name={`content.${index}.content`}
                                                    control={control}
                                                    defaultValue={watch(`content.${index}.content`)}
                                                  />
                                                )}
                                              </>
                                            )}

                                            {isLoading ? (
                                              <Skeleton width="100%" height="50vh" count={1} />
                                            ) : (
                                              <>
                                                {watch(`content.${index}.typeEditor`) === EditorType.HTML_EDITOR && (
                                                  <HTTMLEditorControl
                                                    defaultValue={watch(`content.${index}.content`)}
                                                    rules={{
                                                      required: {
                                                        value: true,
                                                        message: 'This field is required!',
                                                      },
                                                    }}
                                                    name={`content.${index}.content`}
                                                    control={control}
                                                    width="100%"
                                                    height="65vh"
                                                  />
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </>
                          );
                        })
                      ) : (
                        <MsNoData />
                      )}
                      <div className="field">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <div className="field">
                            {isLoading ? (
                              <Skeleton count={1} />
                            ) : (
                              <button type="submit" className="button is-primary">
                                <span>
                                  <FormattedMessage id="Submit" />
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePopupWui;
