import React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { useController } from 'react-hook-form';
import { LANGUAGE, useLang } from '../../hook/use-lang';

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    errors?: boolean;
    isViewOnly?: boolean;
    defaultValue?: string;
  }
>;
export const EditorControl: CFC = ({ name, control, rules, errors, defaultValue = '', isViewOnly }) => {
  const { local } = useLang();
  const {
    field: { onChange },
  } = useController({ name, rules, control });
  return (
    <div className="editor-control-wrapper field-body" style={{ border: errors ? '1px solid red' : '' }}>
      <CKEditor
        name={name}
        initData={defaultValue}
        onChange={(e: any) => onChange(e.editor.getData())}
        readOnly={!!isViewOnly}
        style={{ minWidth: '100%', minHeight: '100%' }}
        config={{
          extraAllowedContent: 'iframe[*]',
          defaultLanguage: LANGUAGE.EN,
          language: local,
          height: 500,
          extraPlugins: 'justify,font,colorbutton',
          fontSize_sizes:
            '8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14x;15/15px;16/16px;18/20px;22/22px;24/24px;26/28px;36/36px;48/48px;72/72px;',
        }}
      />
    </div>
  );
};
// toolbar http://cdn.ckeditor.com/4.13.0/full-all/samples/toolbarconfigurator/index.html#basic
// config https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html
