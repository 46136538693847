/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { onEsc } from '../utils/key-board';

type UseModalState = {
  showModal: (content: React.ReactNode, bgDismiss?: boolean, size?: 'small' | 'w60' | 'medium' | 'w100') => void;
  onHide: () => void;
};

const Ctx = createContext({} as UseModalState);

export interface IModalProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider: React.FC<IModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>();
  const [size, setSize] = useState<string>();
  const [bgDismiss, setBgDismiss] = useState(true);

  function showModal(content: React.ReactNode, bgDismiss?: boolean, size?: 'small' | 'w60' | 'medium' | 'w100') {
    setBgDismiss(bgDismiss != null ? bgDismiss : true);
    setSize(size);
    setContent(content);
    setIsOpen(true);
  }

  const onHide = () => {
    setIsOpen(false);
    setContent(undefined);
  };

  const escFunc = useCallback((e: globalThis.KeyboardEvent) => {
    onEsc(e, () => onHide());
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', escFunc);
      document.querySelector('html')?.classList.add('is-clipped');
    } else {
      window.removeEventListener('keydown', escFunc);
      document.querySelector('html')?.classList.remove('is-clipped');
    }
  }, [escFunc, isOpen]);

  const memo = useMemo(() => {
    return (
      <div id="useModal" className={`modal${isOpen ? ' is-active' : ''}`}>
        <div className="modal-background" onClick={() => bgDismiss && onHide()} />
        <div className={`modal-content is-overflow-hidden${size ? ` ${size}` : ''}`}>
          {content}

          <button
            className={`modal-close is-large${!bgDismiss ? ' is-hidden' : ''}`}
            aria-label="close"
            onClick={onHide}
          />
        </div>
      </div>
    );
  }, [bgDismiss, content, isOpen, size]);

  return (
    <Ctx.Provider value={{ showModal, onHide }}>
      {children}
      {memo}
    </Ctx.Provider>
  );
};

export const useModal = () => useContext(Ctx);
