/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useModal } from '../../../ms-core/hook/use-modal';
import { changePasswordAdmin, changePasswordAdminOnly } from '../../../services/account/admins';

type Props = {
  data: {
    username: string;
    id: string;
  };
  isAdminOnly?: boolean;
  notTitle?: boolean;
  isText?: boolean;
};

export const ChangePasswordButton = (props: Props) => {
  const { showModal, onHide } = useModal();

  const { formatMessage } = useIntl();

  const onClick = () => {
    showModal(
      <ChangePasswordForm
        notTitle={props.notTitle}
        data={props.data}
        onHide={onHide}
        isAdminOnly={props.isAdminOnly}
      />,
    );
  };

  return (
    <>
      {props.isText ? (
        <a className="is-flex dropdown-item navbar-link is-arrowless" onClick={onClick}>
          <span className="icon">
            <i className="mdi mdi-lock-reset" />
          </span>
          <div className="is-black">
            <span>
              <FormattedMessage id="Change password" />
            </span>
          </div>
        </a>
      ) : (
        <button
          className="button is-primary ml-2 has-tooltip-arrow"
          onClick={onClick}
          data-tooltip={formatMessage({ id: 'Change password' })}
        >
          <span className="icon">
            <i className="mdi mdi-18px mdi-lock-reset" />{' '}
          </span>
        </button>
      )}
    </>
  );
};

interface ChangePasswordFormProps {
  data: {
    username: string;
    id: string;
  };
  onHide?: () => void;
  isAdminOnly?: boolean;
  notTitle?: boolean;
}

type PasswordData = {
  newPassword: string;
  password: string;
  confirmPassword: string;
};

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { notTitle = false } = props;
  const { register, handleSubmit, reset, errors, watch } = useForm<PasswordData>();
  const { formatMessage } = useIntl();
  const [isLoading, setLoading] = useState<boolean>(false);
  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');
  const onSubmit = (values: any) => {
    setLoading(true);
    const data = {
      ...values,
      id: props.data.id,
    };
    (props.isAdminOnly ? changePasswordAdminOnly : changePasswordAdmin)(data)
      .then(() => {
        toast.success(
          formatMessage({ id: 'Change Password for {name} success' }, { name: props.data.username ?? props.data.id }),
        );
        reset();
        props?.onHide?.();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card VERIFY_USER_ID-card">
      {!notTitle && (
        <header className="card-header">
          <p className="card-header-title p-3">
            {props.data.username && (
              <FormattedMessage id="Change Password for {name}" values={{ name: props.data.username }} />
            )}
          </p>
        </header>
      )}

      <div className="card-content p-0" style={{ maxHeight: 600, overflow: 'auto' }}>
        <div className="column ">
          <div className="column is-full">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="Password" />
                  &nbsp;<b className="has-text-danger">*</b>
                </label>
                <div className="control has-icons-left">
                  <input
                    name="password"
                    className={`input${errors.password ? ' is-danger' : ''}`}
                    type="password"
                    placeholder={formatMessage({ id: 'At least 6 characters' })}
                    ref={register({
                      required: true,
                      minLength: {
                        value: 6,
                        message: formatMessage({ id: 'Password length is a minimum of 6 characters' }),
                      },
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="mdi mdi-lock" />
                  </span>
                  {errors.password && <p className="help has-text-danger">{errors.password.message}</p>}
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="New Password" />
                  &nbsp;<b className="has-text-danger">*</b>
                </label>
                <div className="control has-icons-left">
                  <input
                    name="newPassword"
                    className={`input${errors.newPassword ? ' is-danger' : ''}`}
                    type="password"
                    placeholder={formatMessage({ id: 'At least 6 characters' })}
                    ref={register({
                      required: true,
                      minLength: {
                        value: 6,
                        message: formatMessage({ id: 'Password length is a minimum of 6 characters' }),
                      },
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="mdi mdi-lock" />
                  </span>
                  {errors.newPassword && <p className="help has-text-danger">{errors.newPassword.message}</p>}
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="Confirm Password" />
                  &nbsp;<b className="has-text-danger">*</b>
                </label>
                <div className="control has-icons-left">
                  <input
                    name="confirmPassword"
                    className={`input${errors.confirmPassword ? ' is-danger' : ''}`}
                    type="password"
                    placeholder={formatMessage({ id: 'At least 6 characters' })}
                    ref={register({
                      required: true,
                      validate: (value) =>
                        value === newPassword.current || formatMessage({ id: 'Confirm password does not match' }),
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="mdi mdi-lock" />
                  </span>
                  {errors.confirmPassword && <p className="help has-text-danger">{errors.confirmPassword.message}</p>}
                </div>
              </div>
              <div className="field is-grouped is-grouped-centered">
                <div className="control">
                  <button type="submit" className={`button is-primary${isLoading ? ' is-loading' : ''}`}>
                    <FormattedMessage id="Submit" />
                    &nbsp;
                  </button>
                </div>
                <div className="control">
                  <button className="button is-warning" onClick={() => props?.onHide?.()} disabled={isLoading}>
                    <FormattedMessage id="Close" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
