/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { useModal } from '../../ms-core/hook/use-modal';
import { ImgServer } from '../image-server';
import './index.scss';

export type Props = {
  href: string;
  className?: string;
  scale?: number;
};

export default function ShowIconImage({ href, className, scale = 2.0 }: Props) {
  const { showModal } = useModal();
  const onClick = () => {
    if (href) {
      showModal(<ModalGallery image={href} />, true, 'w60');
    }
  };

  return (
    <div
      style={{ backgroundColor: '#dcf2ff', width: '24px', height: '24px' }}
      className="card-icon-image"
      onClick={onClick}
    >
      <ImgServer style={{ transform: `scale(${scale})` }} className="is-rounded" src={href} alt={href} />
    </div>
  );
}

type ModalGalleryProps = {
  image: string;
};

export const ModalGallery = (props: ModalGalleryProps) => {
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
      <ImgServer className="is-rounded" src={props.image} alt={props.image} />
    </div>
  );
};
