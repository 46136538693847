import enImage from './en.png';
import viImage from './vi.png';
import zhImage from './zh.png';
import thImage from './th.png';
import idImage from './id.png';

import { LANGUAGE } from '../../../hook/use-lang';

export const MultipleLanguageImg = {
  [LANGUAGE.EN]: { image: enImage, alt: 'English' },
  [LANGUAGE.VI]: { image: viImage, alt: 'Tiếng Việt' },
  [LANGUAGE.ZH]: { image: zhImage, alt: '中国人' },
  [LANGUAGE.TH]: { image: thImage, alt: 'ไทย' },
  [LANGUAGE.ID]: { image: idImage, alt: 'bahasa Indo' },
} as { [key: string]: { image: string; alt: string } };
