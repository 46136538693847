/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { DataSource } from '../../ms-core/types/status-source-type';
import { useIntl } from 'react-intl';
import { keys } from 'lodash';

type Props = {
  label?: string;
  name: string;
  options: DataSource;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  errors?: any;
  onChangeData?: (data: string) => void;
  disableAllValue?: boolean;
  isNormalSize?: boolean;
  notTranslation?: boolean;
  disabled?: boolean;
};
const CustomSelectWithOnChange = (props: Props) => {
  const {
    options,
    name,
    label,
    defaultValue,
    placeholder,
    className,
    errors,
    onChangeData,
    disableAllValue,
    isNormalSize,
    notTranslation,
    disabled,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <div className={`${className || ''} select${isNormalSize ? '' : ' is-small'}${errors ? ' is-danger' : ''}`}>
      <select
        className={`${className || ''}`}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
        onChange={(e) => onChangeData && onChangeData(e.target.value)}
      >
        {!disableAllValue && (
          <option key="" value="">
            {placeholder ? formatMessage({ id: placeholder }) : ''}
          </option>
        )}
        {options &&
          keys(options).map((item: any) => (
            <option key={item} value={item}>
              {options?.[item].label && !notTranslation
                ? formatMessage({ id: options?.[item].label })
                : options?.[item].label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default CustomSelectWithOnChange;
