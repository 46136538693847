export const DATE_TIME_FORMAT_STRING = {
  DATE: 'DD/MM/YYYY',
  MONTH: 'MM/YYYY',
  DATETIME: 'DD/MM/YYYY HH:mm:ss',
  TIME: 'HH:mm:ss',
};
export const DEFAULT_TIMEZONE = {
  VN: 'Asia/Ho_Chi_Minh',
  UK: 'America/Los_Angeles',
};
