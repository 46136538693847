import React from 'react';
import { ProvideLang } from './hook/use-lang';
import { App } from './App';

export const loadLocaleData = (locale: string) => {
  switch (locale) {
    case 'en':
      return import('./languages/en.json');
    case 'zh':
      return import('./languages/zh.json');
    case 'th':
      return import('./languages/th.json');
    case 'id':
      return import('./languages/id.json');
    default:
      return import('./languages/vi.json');
  }
};

export const bootstrapApplication = async (locale = 'vi') => {
  const messages: any = {
    vi: await (await loadLocaleData('vi')).default,
    en: await (await loadLocaleData('en')).default,
    zh: await (await loadLocaleData('zh')).default,
    th: await (await loadLocaleData('th')).default,
    id: await (await loadLocaleData('id')).default,
  };

  return (
    <ProvideLang>
      <App locale={locale} messages={messages} />
    </ProvideLang>
  );
};
