import { DataSource } from '../../ms-core/types/status-source-type';

export enum EditorType {
  HTML_EDITOR = 'HTML_EDITOR',
  WORD_EDITOR = 'WORD_EDITOR',
}

export const EditorTypeSource: DataSource = {
  [EditorType.HTML_EDITOR]: { label: 'Html Editor', color: 'info', value: EditorType.HTML_EDITOR },
  [EditorType.WORD_EDITOR]: { label: 'Word Editor', color: 'warning', value: EditorType.WORD_EDITOR },
};
