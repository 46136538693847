/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
// eslint-disable-next-line react/no-unstable-nested-components
/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import FormControlModal from '../../../../../components/form-detail/FormModal';
import { API_ENDPOINT } from '../../../../../constants/api-endpoint';
import { MsIndex } from '../../../../../ms-core/blocks/ms-index';
import { MsType } from '../../../../../ms-core/models/ms-type';
import 'react-loading-skeleton/dist/skeleton.css';

type Props = {
  data: Record<string, any>[];
  isLoading: boolean;
  fetchData: () => void;
};

const listLangConfDetailForm: MsType[] = [
  {
    name: 'Name',
    field: 'name',
    type: 'text',
    required: true,
  },
  {
    name: 'Language',
    field: 'language',
    type: 'text',
    required: true,
  },
];

export const FormControlLang = memo((props: Props) => {
  const { data, isLoading, fetchData } = props;

  const listLangConf: MsType[] = [
    {
      name: '',
      field: 'id',
      type: 'status',
      displayType: 'customizing',
      customizeIndexData: (_, d) => d,
      customizeIndexDisplay: (d, _, e) => (
        <td key="id" className="has-text-centered is-narrow is-overflow-visible">
          <FormControlModal
            title="Update language"
            className="is-primary"
            icon="dots-horizontal"
            conf={listLangConfDetailForm}
            resourceEndpoint={`${API_ENDPOINT.UI_CONFIG_BUILDER}/languages/${d?.language}`}
            confirmField={{
              field: 'name',
              type: 'text',
            }}
            refreshData={fetchData}
            method="patch"
            editFrom={{
              defaultValues: d,
              fetchData: {
                id: d.id,
              },
            }}
          />
        </td>
      ),
    },
    {
      name: 'Name',
      field: 'name',
      type: 'text',
      required: true,
    },
    {
      name: 'Language',
      field: 'language',
      type: 'text',
      required: true,
    },
  ];

  return (
    <div className="field is-horizontal p-5 is-border">
      <div className="field-label is-normal has-text-left pb-4">
        <div className="label">{isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Genneral" />}</div>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <div className="info-description">
              <div className="field pt-2">
                <div className="control">
                  {isLoading ? (
                    <Skeleton count={6} />
                  ) : (
                    <MsIndex
                      listRequested={isLoading}
                      conf={listLangConf}
                      items={data}
                      title="List language"
                      iconTitle="file-account"
                      notAutoFetchData
                      renderRightHeader={() => {
                        return (
                          <FormControlModal
                            title="Add language"
                            className="is-primary"
                            icon="plus"
                            conf={listLangConfDetailForm}
                            resourceEndpoint={`${API_ENDPOINT.UI_CONFIG_BUILDER}/languages`}
                            confirmField={{
                              field: 'name',
                              type: 'text',
                            }}
                            buttonText
                            refreshData={fetchData}
                          />
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
