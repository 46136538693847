import React from 'react';
import { useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import checkboxImage from '../../assets/common/checkbox.svg';
import './index.scss';

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    onChangeData?: (e: string | number) => void;
    title?: string;
    disabled?: boolean;
    defaultValue?: boolean;
  }
>;
const CheckBoxControl: CFC = ({ name, control, rules, title, disabled, defaultValue, onChangeData }) => {
  const {
    field: { onChange },
  } = useController({ name, rules, control });
  return (
    <div className=" checkbox-warper">
      <label className="checkbox-control is-flex">
        <input
          onChange={(e) => onChange(e.target.checked)}
          checked={defaultValue}
          disabled={disabled}
          className="checkbox-control__input"
          type="checkbox"
        />

        <span className={`checkbox-control__icon ${disabled && 'checkbox-control__icon--disabled'}`}>
          <img src={checkboxImage} />
        </span>
        <span className="pl-3 is-clickable	">{title && <FormattedMessage id={title} />}</span>
      </label>
    </div>
  );
};

export default CheckBoxControl;
