import * as React from 'react';
import { PLATFORM } from '../../utils/secrets';
import { IFooterProps } from './footer.type';

export const Footer: React.FC<IFooterProps> = () => {
  return (
    <footer className="footer has-background-light">
      <div className="container-fluid">
        <div className="level">
          <div className="level-left">
            <div className="level-item has-text-grey">© 2024, {PLATFORM}</div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
