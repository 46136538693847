import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '../../../../../../../components/form-detail/FormControl';
import { useModal } from '../../../../../../../ms-core/hook/use-modal';
import { showToast } from '../../../../../../../ms-core/utils/toast';
import { updateUIConfigById } from '../../../../../../../services/common/website-builders';
import { fGenerateUniqueID } from '../../../../../../../utils/helper';
import { IDetailProps, IDetailFormProp } from './detail.type';

export const DetailButton: React.FC<IDetailProps> = (props) => {
  const { refreshData, items, item, isCreate, conf, sectionId, lang, response, nameKey } = props;
  const { showModal, onHide } = useModal();

  const onClick = useCallback(() => {
    showModal(
      <DetailForm
        conf={conf}
        data={item}
        items={items}
        onHide={onHide}
        refreshData={refreshData}
        isCreate={isCreate}
        sectionId={sectionId}
        lang={lang}
        response={response}
        nameKey={nameKey}
      />,
      false,
    );
  }, [conf, isCreate, item, items, lang, nameKey, onHide, refreshData, response, sectionId, showModal]);

  return (
    <button className={`${isCreate ? 'is-small' : ''} button is-primary`} onClick={onClick}>
      <span className="icon">
        <i className={`mdi ${isCreate ? '' : 'mdi-18px'} mdi-${isCreate ? 'plus' : 'dots-horizontal'}  `} />
      </span>
      {isCreate && (
        <span>
          <FormattedMessage id="Create" />
        </span>
      )}
    </button>
  );
};

export const DetailForm: React.FC<IDetailFormProp> = (props) => {
  const { refreshData, data, isCreate, onHide, conf, items, sectionId, lang, response, nameKey } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onUpdateData = useCallback(
    (values: Record<string, any>) => {
      setIsLoading(true);
      updateUIConfigById(values, lang, sectionId)
        .then(() => {
          showToast.success('Update Resource Success');
          refreshData();
          onHide();
        })
        .finally(() => setIsLoading(false));
    },
    [lang, onHide, refreshData, sectionId],
  );

  const onSubmit = useCallback(
    (values: Record<string, any>) => {
      if (isCreate) {
        onUpdateData({
          ...response,
          ...{ [nameKey]: [...items, { id: values ? values.id : fGenerateUniqueID(), ...values }] },
        });
      } else {
        onUpdateData({
          ...response,
          ...{ [nameKey]: [...items.filter((i) => `${i.id}` !== `${data?.id}`), { ...data, ...values }] },
        });
      }
    },
    [data, isCreate, items, nameKey, onUpdateData, response],
  );

  return (
    <FormControl
      isLoading={isLoading}
      title={isCreate ? 'Create' : 'Update'}
      conf={conf}
      defaultValues={isCreate ? {} : data}
      onSubmitData={onSubmit}
      closeModal={onHide}
    />
  );
};
