import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-searchbox';
import './index.scss';
import IframeControl from '../iframe-control';

// const AceEditor = (window as any).ace.Editor;

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    defaultValue?: string;
    readOnly?: boolean;
    width?: string;
    height?: string;
  }
>;

export const HTTMLEditorControl: CFC = ({
  name,
  control,
  rules,
  defaultValue = '',
  readOnly = false,
  width = '100%',
  height = '65vh',
}) => {
  const {
    field: { onChange },
  } = useController({ name, rules, control });

  const [previewHtml, setPreviewHtml] = useState(false);

  return (
    <>
      <div className="button is-small mt-1 mb-5" onClick={() => setPreviewHtml(!previewHtml)}>
        <span className="icon">
          <i className="mdi mdi-18px  mdi-code-tags" />
        </span>
        <span>Source</span>
      </div>
      <input type="hidden" name={name} />
      {previewHtml ? (
        <IframeControl content={defaultValue} width={width} height={height} />
      ) : (
        <AceEditor
          className="REACT-ACE"
          value={defaultValue}
          mode="html"
          theme="monokai"
          onChange={onChange}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          width={width}
          height={height}
          enableBasicAutocompletion
          showPrintMargin={false}
          fontSize={13.5}
          readOnly={readOnly}
          showGutter
          highlightActiveLine
          setOptions={{
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 4,
            showPrintMargin: false,
            hScrollBarAlwaysVisible: true,
            vScrollBarAlwaysVisible: true,
            autoScrollEditorIntoView: false,
            scrollPastEnd: true,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
          }}
        />
      )}
    </>
  );
};
