import React, { useState } from 'react';
import { copyToClipboard } from '../../ms-core/utils/copy';
import { showToast } from '../../ms-core/utils/toast';
import { ICopyIconProp } from './copy-icon.type';

export const CopyIconRight: React.FC<ICopyIconProp> = (props) => {
  const { value } = props;

  const [isCopy, setIsCopy] = useState(false);
  return (
    <span
      className={`icon is-small is-clickable  is-right ${isCopy ? 'has-text-info' : 'has-text-danger'}`}
      onClick={async () => {
        const result = await copyToClipboard(value);
        if (result) {
          setIsCopy(true);
          showToast.success('Copied');
        } else {
          setIsCopy(false);
          showToast.warning('Cannot copy, please copy manual!');
        }
      }}
    >
      <i className="mdi mdi-content-copy" />
    </span>
  );
};
