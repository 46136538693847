/* eslint-disable no-prototype-builtins */
import { Pagination } from '../ms-core/models/pagination';

export const sortData = (query: string, items: any) => {
  if (query == null) return items;
  const sortBy = query.split(',');

  if (typeof items[0]?.[sortBy[0]] === 'number') {
    switch (sortBy[1]) {
      case 'ASC':
        items.sort((a: any, b: any) => {
          const valueA = a?.[sortBy[0]] ?? 0; // Safe fallback value
          const valueB = b?.[sortBy[0]] ?? 0; // Safe fallback value
          return valueA - valueB;
        });
        break;
      case 'DESC':
        items.sort((a: any, b: any) => {
          const valueA = a?.[sortBy[0]] ?? 0; // Safe fallback value
          const valueB = b?.[sortBy[0]] ?? 0; // Safe fallback value
          return valueB - valueA;
        });
        break;
      default:
        return items;
    }
  } else if (new Date(items[0]?.[sortBy[0]]).getTime() > 0) {
    switch (sortBy[1]) {
      case 'ASC':
        items.sort((a: any, b: any) => {
          return a.createdAt.localeCompare(b.createdAt);
        });
        break;
      case 'DESC':
        items.sort((a: any, b: any) => {
          return b.createdAt.localeCompare(a.createdAt);
        });
        break;
      default:
        return items;
    }
  }
  return items;
};

export const toLowerCaseLetter = (data: string): string => {
  const temp = data.split('_').join(' ');
  return temp.toLocaleLowerCase();
};

export const toUpperCaseLetter = (data: string): string => {
  const temp = data.split('_').join(' ');
  return temp.toUpperCase();
};

export const capitalizeFirstLetter = (data: string): string => {
  const temp = data.split('_').join(' ');
  return temp[0].toUpperCase() + temp.slice(1).toLocaleLowerCase();
};

export const toTitleCase = (str: string, split?: string) => {
  if (!str) return '';
  return str
    .split(split || '_')
    .join(' ')
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const intlNumberFmt = (val: any) => Intl.NumberFormat().format(val.toFixed(2));

export const customPagination = (data: any): Pagination => {
  return {
    number: data.number + 1,
    size: data.size,
    totalElements: data.totalElements,
    totalPages: data.totalPages,
  };
};

export const convertToInternationalCurrencySystem = (value: number) => {
  return Math.abs(Number(value)) >= 1.0e9
    ? `${(Math.abs(Number(value)) / 1.0e9).toFixed(0)}B`
    : Math.abs(Number(value)) >= 1.0e6
    ? `${(Math.abs(Number(value)) / 1.0e6).toFixed(0)}M`
    : Math.abs(Number(value)) >= 1.0e3
    ? `${(Math.abs(Number(value)) / 1.0e3).toFixed(0)}K`
    : Math.abs(Number(value));
};

export const fGenerateUniqueID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    const v = c === 'x' ? r : (r % 4) + 8;
    return v.toString(16);
  });
};

export const mergeConsecutiveElementsArray = (array: any[], size: number, offsetData?: number) => {
  let offset = offsetData ?? 0;
  const result = [];
  while (offset < array.length) {
    result.push(array.slice(offset, (offset += size)));
  }
  return result;
};

export const ExcelDateToJSDate = (serial: number) => {
  const utc_days = Math.floor((serial ?? 0) - 25569); // Safe fallback value
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const fractional_day = (serial ?? 0) - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;

  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
};

export const removeDuplicates = (array: Record<string, any>, fieldCheck: string) => {
  return array.filter(
    (v: Record<string, any>, i: number, a: Record<string, any>[]) =>
      a.findIndex((v2) => v2?.[fieldCheck] === v?.[fieldCheck]) === i,
  );
};

export const formatHtml = (html: string) => {
  const tab = '\t';
  let result = '';
  let indent = '';

  html?.split(/>\s*</).forEach((element: string) => {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += `${indent}<${element}>\r\n`;

    if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
};

export const isEmpty = (value: any): boolean => {
  return (
    value == null ||
    (value.hasOwnProperty('length') && value.length === 0) ||
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
  if (newIndex >= arr.length) {
    for (let k = newIndex - arr.length + 1; k > 0; k -= 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const f3digits = (amt?: number) => Intl.NumberFormat().format(Math.floor(amt || 0));

export const getClassNameWithAmt = (amt?: number) => {
  if (!amt || amt === 0) {
    return '';
  }
  if (amt > 0) {
    return 'has-text-success has-background-success-light';
  }
  return 'has-text-danger has-background-danger-light';
};

export const convertToSlug = (text: string, categoryId: string): string =>
  `/${categoryId ? `${categoryId}/` : ''}${text
    .toLowerCase()
    .replace(/[^\w\s]/g, '')
    .trim()
    .replace(/\s+/g, '-')}`;
