import { stringifyUrl } from 'query-string';
import { MS_API } from '../../ms-core/utils/api';

export const getAdminUIConfigById = (language: string, section: string) => {
  return new Promise<Record<string, any>>((resolve, reject) => {
    MS_API.get<Record<string, any>>(
      stringifyUrl(
        { url: `/common/admin/ui-config/${language}?section=${section}` },
        { skipEmptyString: true, skipNull: true },
      ),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const updateAdminUIConfigById = (data: Record<string, any>, language: string, section: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(stringifyUrl({ url: `/common/admin/ui-config/${language}?section=${section}` }), data)
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const refreshAdminUIConfigById = (language: string, section: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.put<never>(stringifyUrl({ url: `/common/admin/ui-config/${language}?section=${section}/refresh` }))
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const getLangAdminUIConfig = () => {
  return new Promise<Record<string, any>[]>((resolve, reject) => {
    MS_API.get<Record<string, any>[]>(
      stringifyUrl({ url: `/common/admin/ui-config/languages` }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const getSectionAdminUIConfig = (lang: string) => {
  return new Promise<string[]>((resolve, reject) => {
    MS_API.get<string[]>(
      stringifyUrl({ url: `/common/admin/ui-config/${lang}/sections` }, { skipEmptyString: true, skipNull: true }),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};

export const createSectionAdminUIConfigById = (data: Record<string, any>, language: string, section: string) => {
  return new Promise<void>((resolve, reject) => {
    MS_API.post<never>(stringifyUrl({ url: `/common/admin/ui-config/${language}?section=${section}` }), data)
      .then(() => resolve())
      .catch(() => reject());
  });
};
