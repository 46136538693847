/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const IframeControl = (props: { content: string; width?: string; height?: string }) => {
  const { content, width = '100%', height = '65vh' } = props;
  let iframe_ref = null;
  const writeHTML = (frame: any) => {
    if (!frame) {
      return;
    }
    iframe_ref = frame;
    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
    frame.style.width = width;
    frame.style.height = height;
    frame.style.border = '1px solid #D3D3D3';
    // frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  };
  return <iframe src="about:blank" scrolling="auto" frameBorder="0" ref={writeHTML} />;
};
export default IframeControl;
