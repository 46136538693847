import * as actions from '../reducers/webSocket';

const socketMiddleware = () => {
  let socket: any = null;

  const onOpen = (store: any) => (event: any) => {
    store.dispatch(actions.wsConnected(event.target.url));
  };

  const onClose = (store: any) => () => {
    store.dispatch((actions as any).wsDisconnected());
  };

  const onMessage = (store: any) => (event: any) => {
    try {
      const payload = JSON.parse(event.data);
      store.dispatch(actions.wsOnMessage(payload));
    } catch (error) {
      // continue regardless of error
    }
  };

  return (store: any) => (next: any) => (action: any) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(action.host);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        break;

      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
