/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { stopPropagation } from '../utils/stop-propagation';

type Props = {
  id?: string;
  className?: string;
  children?: React.ReactNode;
};

const UserLink = (props: Props) =>
  props.id ? (
    <Link className={`${props.className} is-text`} to={`/accounts/${props.id}`} onClick={stopPropagation}>
      {props.children}
    </Link>
  ) : (
    <span className={props.className}>{props.children}</span>
  );

export default UserLink;
