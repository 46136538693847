/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../ms-core/hook/use-modal';
import { showToast } from '../../ms-core/utils/toast';
import { TYPE_FILE, MAX_SIZE_OF_FILE } from '../../utils/secrets';
import { ImgServer } from '../image-server';
import { ScrollBarControl } from '../scroll-bar-control';

export const ModalUploadFile = () => {
  const { showModal } = useModal();
  const onClick = () => {
    showModal(<ModalUploadFileServicer />);
  };

  return (
    <button type="button" className="button is-small is-primary" onClick={onClick}>
      <span className="icon">
        <i className="mdi mdi-cloud-upload default" />
      </span>
      <span>
        <FormattedMessage id="Upload Image" />
      </span>
    </button>
  );
};

type ModalUploadFileServicerProps = {
  onChange?: (e?: File | string) => void;
  hiddenCopy?: boolean;
  onHide?: () => void;
  isHasCard?: boolean;
  defaultValue?: string;
  type?: string[];
  size?: number;
};

export const ModalUploadFileServicer = (props: ModalUploadFileServicerProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [file, setFile] = useState<File>();

  const [previewFile, setPreviewFile] = useState<any>();

  const [errorUpload, setErrorUpload] = useState(false);

  const { isHasCard = true, type = TYPE_FILE, size = MAX_SIZE_OF_FILE } = props;

  const getBase64 = (fl: File) => {
    props?.onChange?.(fl);
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(fl);
    reader.onload = () => {
      setErrorUpload(false);
      setPreviewFile(reader.result);
      setLoading(false);
    };
  };

  const returnFormUpload = () => {
    return (
      <div className="field">
        <div className="control">
          <div className="field">
            <label className="label" />
            <div className="control">
              <ScrollBarControl id="upload" style={{ maxHeight: '350px' }}>
                <ImgServer isLoading={isLoading} className="image-banner" src={previewFile} alt="" />
              </ScrollBarControl>
            </div>
          </div>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className={`file is-small has-name${errorUpload ? ' is-danger' : ''}`}>
                  <label className="file-label">
                    <input
                      type="file"
                      className="file-input"
                      accept={type.join(',')}
                      onChange={(e) => {
                        const fl = e.target.files?.[0];
                        if (!!fl && type.includes(fl.type) && fl.size <= size) {
                          setFile(fl);
                          getBase64(fl);
                        } else {
                          showToast.warn('Invalid uploaded image format');
                        }
                      }}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                      <span className="file-label">
                        <FormattedMessage id="Choose a file…" />
                      </span>
                    </span>
                    <span className="file-name">{file?.name ?? <FormattedMessage id="No file chose" />}</span>
                  </label>
                </div>
              </div>
            </div>
            {previewFile && (
              <div className="level-right ml-4">
                <div className="level-item">
                  <div
                    onClick={() => props?.onChange?.('')}
                    className={`button is-small is-danger ${isLoading ? ' is-loading' : ''}`}
                  >
                    <span className="icon">
                      <i className="mdi mdi-cloud-upload default" />
                    </span>
                    <span>
                      <FormattedMessage id="Delete Image" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setPreviewFile(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <>
      {isHasCard ? (
        <div className="card modal-upload-image">
          <header className="card-header">
            <p className="card-header-title p-3">
              <FormattedMessage id="Upload Image" />
            </p>
          </header>
          <div className="card-content p-0">
            <div className="column ">
              <div className="column is-full">{returnFormUpload()}</div>
            </div>
          </div>
        </div>
      ) : (
        returnFormUpload()
      )}
    </>
  );
};
