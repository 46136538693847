import React from 'react';
import { useController } from 'react-hook-form';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-searchbox';
import './ace-editor.style.scss';

// const AceEditor = (window as any).ace.Editor;

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    defaultValue?: Record<string, any>;
    readOnly?: boolean;
  }
>;

export const JSONEditorControl: CFC = ({ name, control, rules, defaultValue = {}, readOnly = false }) => {
  const {
    field: { onChange },
  } = useController({ name, rules, control });

  return (
    <>
      <input type="hidden" name={name} />
      <AceEditor
        className="ace-editor-component"
        defaultValue={JSON.stringify(defaultValue, undefined, 4)}
        mode="json"
        theme="monokai"
        onChange={onChange}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
        width="100%"
        height="80vh"
        enableBasicAutocompletion
        showPrintMargin={false}
        fontSize={13.5}
        readOnly={readOnly}
        showGutter
        highlightActiveLine
        setOptions={{
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 4,
          showPrintMargin: false,
          hScrollBarAlwaysVisible: true,
          vScrollBarAlwaysVisible: true,
          autoScrollEditorIntoView: false,
          scrollPastEnd: true,
        }}
      />
    </>
  );
};
