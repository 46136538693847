import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ValuesType } from 'utility-types';

export type DataFormCheckBox = {
  id: string;
  data: ValuesType<any>;
  checked: boolean;
};

type Props = {
  rowData: ValuesType<any>;
  queryParam?: Record<string, unknown>;
  identify: string;
  getDataCheckBox: DataFormCheckBox[];
  listRequested?: boolean;
  setDataCheckBox: (obj: DataFormCheckBox) => void;
  setAllDataCheckBox: (obj: DataFormCheckBox[]) => void;
  setDefaultCheckBox?: () => void;
};

export const CheckBoxTable = (props: Props) => {
  const { watch, register, setValue } = useForm<DataFormCheckBox>({ shouldUnregister: false });

  const {
    rowData,
    queryParam,
    identify,
    getDataCheckBox,
    setDataCheckBox,
    setAllDataCheckBox,
    setDefaultCheckBox,
    listRequested,
  } = props;

  const [dataCheckBoxSelect, setDataCheckBoxSelect] = useState<boolean>();

  useEffect(() => {
    if (!rowData || !identify) return;

    if (!!getDataCheckBox && getDataCheckBox.length > 0) {
      setValue(
        rowData?.[identify].toString(),
        getDataCheckBox.filter((item: any) => item?.id.toString() === rowData?.[identify].toString())[0]?.checked,
      );
      setDataCheckBoxSelect(
        getDataCheckBox.filter((item: any) => item?.id.toString() === rowData?.[identify].toString())[0]?.checked,
      );
    } else {
      setValue(rowData?.[identify].toString(), false);
      setDataCheckBoxSelect(false);
    }
  }, [getDataCheckBox]);

  useEffect(() => {
    if (listRequested) return;
    setDefaultCheckBox?.();
  }, [listRequested]);

  return (
    <td className="checkbox-cell has-text-centered is-unset-max-width is-overflow-visible is-narrow">
      <label className="b-checkbox checkbox">
        <input
          ref={register}
          name={rowData?.[identify].toString()}
          onChange={(e) => {
            setValue(rowData?.[identify].toString(), e.target.checked);
            setDataCheckBox({
              id: rowData?.[identify].toString(),
              checked: e.target.checked,
              data: rowData,
            });
          }}
          type="checkbox"
          checked={dataCheckBoxSelect}
        />
        <span className="check" />
        <span className="control-label" />
      </label>
    </td>
  );
};

type CheckBoxTableAllProps = {
  items: ValuesType<any>;
  queryParam?: Record<string, unknown>;
  identify: string;
  getDataCheckBox: DataFormCheckBox[];
  setDataCheckBox: (obj: DataFormCheckBox) => void;
  setAllDataCheckBox: (obj: DataFormCheckBox[]) => void;
  setDefaultCheckBox?: () => void;
  isChecked?: {
    checked: boolean;
    identify: string;
    notUncheck?: boolean;
  };
  listRequested?: boolean;
};

export const CheckBoxTableAll = (props: CheckBoxTableAllProps) => {
  const [checked, setChecked] = useState(false);
  const {
    items,
    queryParam,
    identify,
    getDataCheckBox,
    setDataCheckBox,
    setAllDataCheckBox,
    listRequested,
    setDefaultCheckBox,
  } = props;

  useEffect(() => {
    if (props?.isChecked?.notUncheck !== true) {
      setChecked(false);
      setAllDataCheckBox([]);
    }
  }, [listRequested]);

  useEffect(() => {
    if (!!getDataCheckBox && getDataCheckBox.length > 0) {
      if (getDataCheckBox.find((item: any) => item.checked === false)) {
        setChecked(false);
      }
      if (getDataCheckBox.filter((item: any) => item.checked === true).length === items.length) {
        setChecked(true);
      }
    } else {
      setChecked(false);
    }
  }, [getDataCheckBox]);

  useEffect(() => {
    return () => {
      setChecked(false);
      setAllDataCheckBox([]);
    };
  }, []);

  useEffect(() => {
    if (listRequested) return;
    setDefaultCheckBox?.();
  }, [listRequested]);

  return (
    <div className="checkbox-cell has-text-centered is-unset-max-width is-overflow-visible is-narrow">
      <label className="b-checkbox checkbox">
        <input
          onChange={(e) => {
            setChecked(e.target.checked);
            if (e.target.checked) {
              const tmp: DataFormCheckBox[] = [];
              !!items &&
                items?.forEach((i: Record<string, any>) => {
                  tmp.push({ id: i?.[identify], checked: true, data: i });
                });
              setAllDataCheckBox(tmp);
            } else {
              setAllDataCheckBox([]);
            }
          }}
          type="checkbox"
          checked={checked}
          disabled={!(!!items && items.length > 0)}
        />
        <span className="check" />
        <span className="control-label" />
      </label>
    </div>
  );
};
