import React, { useRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  onChange: (e: string) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  rows?: number;
  style?: React.CSSProperties;
};

export const TextareaControl = (props: Props) => {
  const { className, defaultValue, onChange, placeholder, style, rows } = props;
  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue] = useState('');
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (textareaRef) {
      (textareaRef as Record<string, any>).current.style.height = '0px';
      const { scrollHeight } = (textareaRef as Record<string, any>).current;
      (textareaRef as Record<string, any>).current.style.height = `${scrollHeight}px`;
    }
  }, [currentValue]);

  useEffect(() => {
    setCurrentValue(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <textarea
      style={{ ...style, overflow: 'hidden' }}
      ref={textareaRef}
      value={currentValue}
      onChange={(e) => {
        onChange?.(e.target.value);
        setCurrentValue(e.target.value);
      }}
      className={`${className || ''} textarea`}
      placeholder={placeholder && formatMessage({ id: placeholder })}
      rows={1}
    />
  );
};
