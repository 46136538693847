export class SectionDynamicBuilder {
  dataSubmit!: Record<string, any>;

  private static instance: SectionDynamicBuilder;

  public static getInstance(): SectionDynamicBuilder {
    if (!SectionDynamicBuilder.instance) {
      SectionDynamicBuilder.instance = new SectionDynamicBuilder();
    }
    return SectionDynamicBuilder.instance;
  }

  setDataSubmit(obj: Record<string, unknown>) {
    SectionDynamicBuilder.getInstance().dataSubmit = obj;
  }

  getDataSubmit(): Record<string, any> {
    return SectionDynamicBuilder.getInstance().dataSubmit;
  }
}
export const SectionDynamic = SectionDynamicBuilder.getInstance();
