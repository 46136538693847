import { stringifyUrl } from 'query-string';
import { MS_API } from '../../ms-core/utils/api';
import { convertParams } from './until';

export const getGallery = (params?: Record<string, any>) => {
  return new Promise<Record<string, any>>((resolve, reject) => {
    MS_API.get<Record<string, any>>(
      stringifyUrl(
        { url: `/common/admin/gallery`, query: convertParams(params) },
        { skipEmptyString: true, skipNull: true },
      ),
    )
      .then((res) => resolve(res.data))
      .catch(() => reject());
  });
};
