import _ from 'lodash';
import React, { useCallback } from 'react';
import FormControl from '../../../../../../../../../components/form-detail/FormControl';
import { useModal } from '../../../../../../../../../ms-core/hook/use-modal';
import { showToast } from '../../../../../../../../../ms-core/utils/toast';
import { arrayMove } from '../../../../../../../../../utils/helper';
import { IPriorityPositionButtonProps, IPriorityPositionFormProps } from './priority-position.type';

export const PriorityPositionButton: React.FC<IPriorityPositionButtonProps> = (props) => {
  const { items, item, nameKey, onUpdateData, isLoading } = props;

  const { showModal, onHide } = useModal();

  function onClick() {
    showModal(
      <PriorityPositionForm
        item={item}
        items={items}
        onHide={onHide}
        nameKey={nameKey}
        onUpdateData={onUpdateData}
        isLoading={isLoading}
      />,
      false,
    );
  }

  return (
    <button className="button is-warning ml-2" onClick={onClick}>
      <span className="icon">
        <i className="mdi mdi-18px mdi-alpha-p-box-outline" />
      </span>
    </button>
  );
};

export const PriorityPositionForm: React.FC<IPriorityPositionFormProps> = (props) => {
  const { item, onHide, items, nameKey, onUpdateData, isLoading } = props;

  const onSubmit = useCallback(
    (values: Record<string, any>) => {
      if (!item?.id) {
        showToast.error('Invalid Id');
      } else if (values.position > items.length) {
        showToast.error('Invalid position');
      } else {
        const tmpData = {};
        const oldIndex = items.findIndex((i) => i.id === item.id);
        _.set(tmpData, [nameKey], arrayMove(items, oldIndex, Number(values?.position) - 1));
        onUpdateData(tmpData, onHide);
      }
    },
    [item?.id, items, nameKey, onHide, onUpdateData],
  );

  return (
    <FormControl
      isLoading={isLoading}
      title="Update position"
      rightHeader={`|| ${item?.id}`}
      conf={[
        {
          field: 'position',
          name: 'Position',
          required: true,
          type: 'number',
          min: 0,
          numberStep: 1,
        },
      ]}
      onSubmitData={onSubmit}
      closeModal={onHide}
    />
  );
};
