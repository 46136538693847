/* eslint-disable no-case-declarations */
import moment from 'moment';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ShowIconImage from '../../../components/show-icon-image';
import { DateFormat, DatetimeFormat, TimeFormat, TimeWithZoneFormat } from '../../const/date-time';
import { DataType } from '../../models/ms-type';
import { DataSource } from '../../types/status-source-type';
import { stopPropagation } from '../../utils/stop-propagation';
import GGMoneyCell from '../gg-money-cell';
import Tag from '../tag';
import UserLink from '../user-link';
import { CopyIconRight } from '../../../components/copy-Icon';
import './index.scss';

interface Props {
  cellType: DataType;
  data: any;
  extData?: unknown;
  dataSource?: DataSource;
  rowSpan?: number;
  tdClassName?: string;
  translation?: boolean;
  currencyDecimal?: number;
  isCopy?: boolean;
}

const MsCell = (props: Props) => {
  const {
    translation = true,
    cellType,
    data,
    extData,
    dataSource,
    rowSpan,
    tdClassName,
    currencyDecimal,
    isCopy,
  } = props;

  let cell: ReactElement;

  switch (cellType) {
    case 'number':
      const number = parseInt(data as string, 10);

      cell = <td className="has-text-right">{isNaN(number) ? '---' : new Intl.NumberFormat().format(Number(data))}</td>;
      break;

    case 'time':
      if (typeof data === 'number') {
        cell = <td>{moment.unix(data).utcOffset(7).format(TimeFormat)}</td>;
      } else {
        cell = data ? <td>{moment.utc(data).utcOffset(7).format(TimeWithZoneFormat)}</td> : <td />;
      }
      break;

    case 'date':
      if (typeof data === 'number') {
        cell = <td>{moment.unix(data).utcOffset(7).format(DateFormat)}</td>;
      } else {
        cell = data ? <td>{moment.utc(data).utcOffset(7).format(DateFormat)}</td> : <td />;
      }
      break;

    case 'datetime':
      if (typeof data === 'number') {
        cell = <td>{moment.unix(data).utcOffset(7).format(DatetimeFormat)}</td>;
      } else {
        cell = data ? <td>{moment.utc(data).utcOffset(7).format(DatetimeFormat)}</td> : <td />;
      }
      break;

    case 'datetime2row':
      if (typeof data === 'number') {
        cell = (
          <td>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-calendar-today" />
              </span>
              {moment.unix(data).utcOffset(7).format(DateFormat)}
            </div>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-clock-outline" />
              </span>
              {moment.unix(data).utcOffset(7).format(TimeFormat)}
            </div>
          </td>
        );
      } else {
        cell = data ? (
          <td>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-calendar-today" />
              </span>
              {moment.utc(data).utcOffset(7).format(DateFormat)}
            </div>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-clock-outline" />
              </span>
              {moment.utc(data).utcOffset(7).format(TimeFormat)}
            </div>
          </td>
        ) : (
          <td />
        );
      }
      break;

    case 'email':
      cell = (
        <td>
          <a href={`mailto:${data}`} target="mailtostaff">
            {data}
          </a>
        </td>
      );
      break;

    case 'url':
      const { label: l, url: u } = data as { label: string; url: string };
      cell = (
        <td>
          <Link to={`${u}`} onClick={stopPropagation}>
            {l}
          </Link>
        </td>
      );
      break;

    case 'urlOpenNewWindows':
      const d = data as { label: string; url: string };
      cell = (
        <td>
          <a href={`#${d.url}`} style={{ marginTop: 4 }} target="_blank" rel="noreferrer">
            {d.label}
          </a>
        </td>
      );
      break;

    case 'bool':
      cell = <td>{data ? <i className="mdi mdi-checkbox-marked has-text-primary mdi-24px" /> : null}</td>;
      break;

    case 'money':
      // const money = parseInt(data as string);
      cell = <GGMoneyCell data={data as number} currencyDecimal={currencyDecimal} />;
      break;

    case 'tag':
      cell = (
        <td className="is-unset-max-width has-text-centered">
          {Array.isArray(data) ? (
            data.map((d, index) => (
              <Tag key={index} color={dataSource?.[d]?.color} className="mr-1">
                {dataSource?.[d]?.label ? (
                  translation ? (
                    dataSource?.[d]?.valueOfTranslation ? (
                      <FormattedMessage id={dataSource?.[d]?.label} values={dataSource?.[d]?.valueOfTranslation} />
                    ) : (
                      <FormattedMessage id={dataSource?.[d]?.label} />
                    )
                  ) : (
                    dataSource?.[d]?.label
                  )
                ) : (
                  d
                )}
              </Tag>
            ))
          ) : !!data != null ? (
            <Tag color={dataSource?.[data]?.color}>
              {dataSource?.[data]?.label ? (
                translation ? (
                  dataSource?.[data]?.valueOfTranslation ? (
                    <FormattedMessage id={dataSource?.[data]?.label} values={dataSource?.[data]?.valueOfTranslation} />
                  ) : (
                    <FormattedMessage id={dataSource?.[data]?.label} />
                  )
                ) : (
                  dataSource?.[data]?.label
                )
              ) : (
                data
              )}
            </Tag>
          ) : (
            ''
          )}
        </td>
      );
      break;

    case 'status':
      cell = (
        <td
          className={`${
            dataSource?.[data as string]?.color ? 'has-text-white' : ''
          } has-text-centered status-cell has-background-${dataSource?.[data as string]?.color}`}
        >
          {dataSource?.[data as string]?.label ? <FormattedMessage id={dataSource?.[data as string]?.label} /> : data}
        </td>
      );
      break;

    case 'select':
      cell = (
        <td className={`has-text-white has-text-centered has-background-${dataSource?.[data as string]?.color}`}>
          {dataSource?.[data as string]?.label ? <FormattedMessage id={dataSource?.[data as string]?.label} /> : data}
        </td>
      );
      break;

    case 'id':
      cell = (
        <td className="has-text-weight-bold is-overflow-visible">
          <div className="has-tooltip-arrow is-flex" data-tooltip={data}>
            {isCopy && (
              <>
                <CopyIconRight value={props?.data} /> &nbsp;
              </>
            )}
            <div className="is-text">{data}</div>
          </div>
        </td>
      );
      break;

    case 'player':
      cell = (
        <td rowSpan={rowSpan} className={`${tdClassName} is-overflow-visible`}>
          <div className="has-tooltip-arrow is-flex" data-tooltip={(data as string[])[1]}>
            {props?.data[1] && <CopyIconRight value={props?.data[1]} />}&nbsp;
            <UserLink className="is-text" id={(data as string[])[0]}>
              {(data as string[])[1]}{' '}
            </UserLink>
          </div>
        </td>
      );
      break;

    case 'imageServiceWithSuggested':
    case 'imageService':
    case 'image':
      const returnImage = () => {
        if (!props?.data || props?.data === '') return <td />;
        return (
          <td>
            <div className="is-flex is-justify-content-center has-text-centered has-text-weight-semibold">
              <ShowIconImage href={props?.data} className="is-24x24 is-inline-block" />
            </div>
          </td>
        );
      };

      cell = returnImage();
      break;

    case 'textMultiline':
      cell = (
        <td className="is-overflow-visible">
          <div className="has-tooltip-multiline" data-tooltip={Array.isArray(data) ? data.join(', ') : data}>
            <div className="is-text">{Array.isArray(data) ? data.join(', ') : data}</div>
          </div>
        </td>
      );
      break;

    default:
      cell = (
        <td className="is-overflow-visible">
          <div
            className="has-tooltip-arrow"
            data-tooltip={typeof data === 'object' ? '' : Array.isArray(data) ? data.join(', ') : data}
          >
            <div className="is-text">{Array.isArray(data) ? data.join(', ') : data}</div>
          </div>
        </td>
      );
      break;
  }

  return cell;
};

export default MsCell;
