import { combineReducers } from 'redux';
import { webSocket } from './webSocket';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  webSocket,
});

export default rootReducer;
