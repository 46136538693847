import { merge } from 'lodash';
import { MsIndexAbstract, MsIndexProps, MsIndexState } from '../../abstracts/ms-index-abstract';

type Props = MsIndexProps & {
  onChangeParams?(obj?: Record<string, unknown>): void;
  renderHeader?: (func: () => React.ReactNode) => React.ReactNode;
  renderSearchLevelLeft?: (e?: MsIndexAbstract<MsIndexProps, MsIndexState>) => React.ReactNode;
  renderMore?: () => React.ReactNode;
  renderRightHeader?: (e?: MsIndexAbstract<MsIndexProps, MsIndexState> | unknown) => React.ReactNode;
  renderTFoot?: (obj?: Record<string, unknown>) => React.ReactNode;
  getDataCheckBox?(obj?: Record<string, unknown>): void;
};

export class MsIndex extends MsIndexAbstract<Props, MsIndexState> {
  onRowClicked() {
    //
  }

  refresh() {
    this.onSearchStart();
  }

  onSearchStart() {
    this.props.get?.(this.props.queryParam ?? {});
  }

  onLevelSearchChanged = (obj?: Record<string, unknown>, isForceUpdate?: boolean) => {
    const searchParams = { ...obj, page: 1, size: 15 };

    this.props.onChangeParams?.(!isForceUpdate ? { ...this.props.queryParam, ...searchParams } : searchParams);

    if (!isForceUpdate) this.props.get?.({ ...this.props.queryParam, ...searchParams });
  };

  onPageChanged = (obj?: Record<string, unknown>) => {
    const newParams = merge({}, this.props.queryParam, obj);
    this.props.onChangeParams?.(newParams);
    this.props.get?.(newParams);
  };

  renderHeadRow() {
    return this.props.renderHeader ? this.props.renderHeader(() => super.renderHeadRow()) : super.renderHeadRow();
  }

  renderSearchLevelLeft() {
    return this.props.renderSearchLevelLeft?.();
  }

  renderTFoot = () => {
    return this.props.renderTFoot?.(this.props);
  };

  renderMore() {
    return this.props.renderMore?.();
  }

  getDataCheckBox() {
    return this.state.defaultCheckBox;
  }

  renderRightHeader() {
    return this.props.renderRightHeader ? this.props.renderRightHeader(this) : super.renderRightHeader();
  }

  onSortChanged = (sort: string) => {
    const newQueryParams = { ...this.props.queryParam, sort };
    this.props.onChangeParams?.(newQueryParams);
    this.onSearchStart();
  };
}
