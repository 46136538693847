import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { IAsideLayoutProp } from './aside.type';
import { Link } from 'react-router-dom';
import navigation, { IMenuGroup, IMenuItem, IMenuSection } from '../../_nav';
import CustomMenuItemNav from '../../components/custom-menu-item';
import { useRouter } from '../../ms-core/utils/routers';
import { LOGO, PLATFORM } from '../../utils/secrets';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Aside: React.FC<IAsideLayoutProp> = () => {
  const router = useRouter();
  const [expandList, setExpandList] = React.useState([] as any[]);

  const handleExpandItem = useCallback(
    (item: IMenuItem | IMenuGroup) => {
      if (expandList.find((_e) => _e === item?.url)) {
        setExpandList(expandList.filter((_e) => _e !== item?.url));
      } else {
        setExpandList([...expandList, `${item?.url}`]);
      }
    },
    [expandList],
  );

  const renderSubMenus = useMemo(() => {
    return (subMenus: IMenuItem[]) => {
      return subMenus?.map((menu: IMenuItem) => {
        const { url = '', icon, valueOfTranslation, name = '' } = menu;
        const isActive = menu?.url === router?.pathname;

        return (
          <li key={url}>
            <Link
              to={url}
              className={`router-link-active has-icon${isActive ? ' is-active' : ''}`}
              style={{ marginLeft: 20 }}
            >
              <span className="icon">
                <i className={`mdi ${icon}`} />
              </span>
              <span className="menu-item-label">
                {valueOfTranslation ? (
                  <FormattedMessage id={name} values={valueOfTranslation} />
                ) : (
                  <FormattedMessage id={name} />
                )}
              </span>
            </Link>
          </li>
        );
      });
    };
  }, [router.pathname]);

  const renderMenus = useMemo(() => {
    return (menus: (IMenuItem | IMenuGroup)[]) => {
      return menus.map((item: IMenuItem | IMenuGroup) => {
        const { url = '', icon, valueOfTranslation, name = '' } = item;
        const isActive = url === router.pathname;
        const isAppend = router.pathname.indexOf(url) === 0;
        const isExpand = !!isAppend || expandList.find((_e) => _e === url);

        const clickMenuBtnHandler = () => {
          if (!isAppend) handleExpandItem(item);
        };

        if ('subMenus' in item && Array.isArray(item?.subMenus)) {
          const subMenus = renderSubMenus(item?.subMenus).filter((e) => e != null);

          return subMenus.length ? (
            <li key={url} className={`is-hidden-x ${isExpand ? 'is-active' : ''}`}>
              <a className="has-icon has-dropdown-icon" onClick={clickMenuBtnHandler} role="menuitem" tabIndex={0}>
                <span className="icon">
                  <i className={`mdi ${icon}`} />
                </span>
                <span className="menu-item-label">
                  {valueOfTranslation ? (
                    <FormattedMessage id={name} values={valueOfTranslation} />
                  ) : (
                    <FormattedMessage id={name} />
                  )}
                </span>
                <div className="dropdown-icon">
                  <span className="icon">
                    <i className={`mdi mdi-${!isExpand ? 'plus' : 'minus'}`} />
                  </span>
                </div>
              </a>
              <ul>{subMenus}</ul>
            </li>
          ) : null;
        }

        return (
          <li key={url} className="is-hidden-x">
            <Link to={url} className={`router-link-active has-icon${isActive ? ' is-active' : ''}`}>
              <CustomMenuItemNav item={item} />
            </Link>
          </li>
        );
      });
    };
  }, [expandList, handleExpandItem, renderSubMenus, router.pathname]);

  const renderNavigation = useMemo(() => {
    return navigation.items.map((nav: IMenuSection, index: number) => {
      const { valueOfTranslation, name, child } = nav;
      const menus = renderMenus(child).filter((e) => e != null);

      return menus.length ? (
        <React.Fragment key={index}>
          <p className="menu-label">
            {valueOfTranslation ? (
              <FormattedMessage id={name} values={valueOfTranslation} />
            ) : (
              <FormattedMessage id={name} />
            )}
          </p>
          <ul className="menu-list">{menus}</ul>
        </React.Fragment>
      ) : null;
    });
  }, [renderMenus]);

  return (
    <aside className="aside is-placed-left is-expanded">
      <div className="aside-tools">
        <div className="aside-tools-label is-clickable">
          &nbsp; &nbsp;
          {PLATFORM}
        </div>
      </div>
      <div
        className="menu is-menu-main is-nowrap"
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 56px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ flex: 1 }}>{renderNavigation}</div>
        <div className="image-container" style={{ marginTop: 'auto', textAlign: 'center' }}>
          <LazyLoadImage
            src={LOGO}
            effect="blur"
            style={{ width: '150px' }} // Set the width of the image
          />
        </div>
      </div>
    </aside>
  );
};
