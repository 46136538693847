import React from 'react';
import { formatCurrencies } from '../../utils/card-money';

interface Props {
  className?: string;
  data: number;
  negativeColor?: string;
  positiveColor?: string;
  addon?: string;
  children?: React.ReactNode;
  minNumber?: number;
  currencyDecimal?: number;
}

const GGMoneyCell = (props: Props) => {
  const { className, data, negativeColor, positiveColor, addon, children, minNumber, currencyDecimal } = props;
  return (
    <td
      className={`has-text-right has-text-weight-semibold has-text-${
        data > (minNumber || 0) ? positiveColor ?? 'success' : negativeColor ?? 'danger'
      } ${className}`}
    >
      {isNaN(data) ? '-' : formatCurrencies.BCOIN(Number(data || ''), props?.currencyDecimal)}
      {addon}
      {children}
    </td>
  );
};

export default GGMoneyCell;
