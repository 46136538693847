/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { onEsc } from '../utils/key-board';

type UseConfirmState = {
  showConfirm: (
    title: string,
    confirmText: string | undefined,
    cb: { onConfirm: () => void | Promise<unknown>; onClose?: () => void | Promise<unknown> },
    rightTitle?: string | undefined,
    descriptionText?: string[] | undefined,
  ) => void;
  onClose?: () => void;
};

const Ctx = createContext({} as UseConfirmState);

export interface IConfirmProviderProps {
  children?: React.ReactNode;
}

export const ConfirmProvider: React.FC<IConfirmProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [confirmText, setConfirmText] = useState<string>();
  const [descriptionText, setDescriptionText] = useState<string[]>();
  const [rightTitle, setRightTitle] = useState<string>();
  const [cb, setCb] = useState<{ onConfirm: () => void | Promise<unknown>; onClose?: () => void | Promise<unknown> }>();
  const { register, watch, reset, setValue } = useForm<{ confirm: string }>({ defaultValues: { confirm: '' } });
  const confirm = watch('confirm');
  const [isLoading, setIsLoading] = useState(false);

  function showConfirm(
    title: string,
    confirmText: string | undefined,
    cb: { onConfirm: () => void | Promise<unknown>; onClose?: () => void | Promise<unknown> },
    rightTitle?: string | undefined,
    descriptionText?: string[] | undefined,
  ) {
    setCb(cb);
    setTitle(title);
    setConfirmText(confirmText);
    setDescriptionText(descriptionText);
    setRightTitle(rightTitle);
    reset();
    setIsLoading(false);
    setIsOpen(true);
  }

  async function onConfirm() {
    setIsLoading(true);
    Promise.resolve(cb?.onConfirm()).finally(() => setIsOpen(false));
  }

  function onClose() {
    Promise.resolve(cb?.onClose?.()).finally(() => {
      onHide();
    });
  }

  function onHide() {
    setIsOpen(false);
    setCb(undefined);
  }

  const escFunc = useCallback((e: globalThis.KeyboardEvent) => {
    onEsc(e, onHide);
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', escFunc);
    } else {
      window.removeEventListener('keydown', escFunc);
    }
  }, [isOpen]);

  const memo = useMemo(() => {
    return (
      <div className={`modal${isOpen ? ' is-active' : ''}`}>
        <div className="modal-background" onClick={() => !isLoading && onClose()} />
        <div className="modal-content small">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title p-3">
                {title && <FormattedMessage id={title} />}
                {rightTitle}
              </p>
            </header>
            <div className="card-content p-3">
              <p className="block">
                {!!descriptionText &&
                  descriptionText?.map((i, k) => {
                    return (
                      <>
                        {i && <FormattedMessage id={i} />}
                        <br />
                        {descriptionText.length > k + 1 && <br />}
                      </>
                    );
                  })}
                {!descriptionText && (
                  <FormattedMessage
                    id="This action <b>cannot</b> be undone. Are you absolutely sure?"
                    values={{
                      b: ((chunk: string) => <b>{chunk}</b>) as any, // Sử dụng FormatXMLElementFn cho hàm
                    }}
                  />
                )}
              </p>
              {!!confirmText && (
                <>
                  <p className="pb-1">
                    <FormattedMessage
                      id="Please type {content} to confirm."
                      values={{
                        content: (
                          <>
                            <b>{confirmText}</b>
                            <span
                              className="icon-text is-clickable has-text-success"
                              onClick={async () => {
                                // const result = await copyToClipboard(confirmText ?? '');
                                // if (result) {
                                //   // showToast.success('Copied');
                                //   setValue('confirm', confirmText);
                                // } else {
                                //   showToast.warning('Cannot copy, please copy manual!');
                                // }
                                setValue('confirm', confirmText);
                              }}
                            >
                              <span className="icon is-primary">
                                <i className="mdi mdi-content-copy" />
                              </span>
                            </span>
                          </>
                        ),
                      }}
                    />
                  </p>
                  <input className="input block" type="text" name="confirm" ref={register} />
                </>
              )}
              <button
                className={`button is-danger is-fullwidth${isLoading ? ' is-loading' : ''}`}
                onClick={() => onConfirm()}
                disabled={!!confirmText && confirm !== `${confirmText}`}
              >
                <FormattedMessage id="Confirm" />
              </button>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => !isLoading && onClose()} />
        </div>
      </div>
    );
  }, [confirm, confirmText, descriptionText, isLoading, isOpen, register, title]);

  return (
    <Ctx.Provider value={{ showConfirm, onClose }}>
      {children}
      {memo}
    </Ctx.Provider>
  );
};

export const useConfirm = () => useContext(Ctx);
