import React, { useState, useContext, createContext } from 'react';

export enum LANGUAGE {
  VI = 'vi',
  EN = 'en',
  ZH = 'zh',
  TH = 'th',
  ID = 'id',
}

const langContext = createContext(
  {} as {
    local: LANGUAGE;
    setLanguage(lang: LANGUAGE): void;
  },
);

// Provider component that wraps our app and makes lang object available to any
// child component that calls useLang().
export const ProvideLang = ({ children }: any) => {
  const lang = useProvideLang();
  return <langContext.Provider value={lang}>{children}</langContext.Provider>;
};

// Hook for child components to get the lang object and re-render when it
// changes.
export const useLang = () => {
  return useContext(langContext);
};

// Provider hook that creates lang object and handles state
function useProvideLang() {
  const [local, setLocal] = useState<LANGUAGE>(
    (window.localStorage.getItem('lang') as LANGUAGE) ??
      JSON?.parse?.(window?.localStorage?.getItem('general-config') as string)?.langDefault! ??
      LANGUAGE.EN,
  );

  const setLanguage = (language: LANGUAGE) => {
    if (language === local) return;

    window.localStorage.setItem('lang', language);
    setLocal(language);
  };

  // Return the user object and lang methods
  return {
    local,
    setLanguage,
  };
}
