export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const PROXY = process.env.REACT_APP_PROXY;

export const PLATFORM = process.env.REACT_APP_PLATFORM;

export const CURRENCY_DECIMAL = 0;

export const LANG_DEFAULT = 'en';

export const CURRENCY_DECIMAL_POWER_CONSTANT = 10 ** CURRENCY_DECIMAL;

export const ENABLE_CAPTCHA_LOGIN = false;

export const CURRENCY = 'VND';

export const ENABLE_LOGIN_OTP = false;

export const ENABLE_UI_CONFIG: string[] = [];

export const ENABLE_WEBSITE_BUILDERS = true;

export const MAX_SIZE_OF_FILE = 2097152;

export const TYPE_FILE = ['image/jpeg', 'image/png', 'image/gif'];

export const LOGO = process.env.REACT_APP_LOGO;
