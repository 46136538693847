import { createStore, applyMiddleware, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';

import socketMiddleware from '../middleware/socketMiddleware';
import rootReducer from '../reducers';

/**
 * @DESC Persistence configuration
 * @deprecated keyPrefix is going to be removed in v6.
 */
const persistConfig = { key: 'msRoot', storage };

/**
 * @DESC It provides a way of combining the reducers, replacing redux's
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * @DESC The parts handle some code put between dispatching action an action,
 * and the moment it reaches the reducer.
 */
const middleWare = [thunkMiddleware, socketMiddleware, process.env.LOG && logger].filter(Boolean) as Middleware[];

/**
 * @DESC Create store that holds the complete state tree of application
 */
const store = createStore(persistedReducer, applyMiddleware(...middleWare));

/**
 * @DESC Once store is created, pass it into `persistStore` function, which
 * ensures redux state is saved to persisted storage whenever it changes.
 */
const persistor = persistStore(store);

export { store, persistor };
