import React, { useEffect, useState } from 'react';
import { FormStatus } from './components/form-field-login/form-status';
import { useRouter } from '../../ms-core/utils/routers';
import { useAuth } from '../../ms-core/utils/auth';
import { FormattedMessage } from 'react-intl';
import { ILoginPageProps } from './login.type';
//  import './login.scss';
import { LoginForm } from './components';

export const LoginPage: React.FC<ILoginPageProps> = () => {
  const [status, setStatus] = useState<number | undefined>(undefined);

  const { push } = useRouter();

  const { accessToken } = useAuth();

  useEffect(() => {
    if (accessToken) {
      push('/dashboard');
    }
  }, [accessToken, push]);

  return (
    <div id="app">
      <section className="Login__section section hero is-fullheight is-error-section">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-two-fifths">
                <div className="card has-card-header-background">
                  <header className="card-header">
                    <p className="card-header-title">
                      <span className="icon">
                        <i className="mdi mdi-lock default" />
                      </span>
                      <span>
                        <FormattedMessage id="Login" />
                      </span>
                    </p>
                  </header>
                  <div className="card-content">
                    {status === 403 && <FormStatus type="error" message="Wrong email or password." />}

                    <LoginForm onAuth={() => push('/dashboard')} onStatus={setStatus} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot has-text-centered">
          <div className="logo" />
        </div>
      </section>
    </div>
  );
};
